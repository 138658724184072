@font-face {
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/lexend/v19/wlptgwvFAVdoq2_F94zlCfv0bz1WCzsX_LA.ttf) format('truetype');
}
@font-face {
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/lexend/v19/wlptgwvFAVdoq2_F94zlCfv0bz1WC7sW_LA.ttf) format('truetype');
}
@font-face {
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/lexend/v19/wlptgwvFAVdoq2_F94zlCfv0bz1WC2UW_LA.ttf) format('truetype');
}
@font-face {
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/lexend/v19/wlptgwvFAVdoq2_F94zlCfv0bz1WCzsW_LA.ttf) format('truetype');
}
@font-face {
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/lexend/v19/wlptgwvFAVdoq2_F94zlCfv0bz1WCwkW_LA.ttf) format('truetype');
}
@font-face {
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/lexend/v19/wlptgwvFAVdoq2_F94zlCfv0bz1WC-UR_LA.ttf) format('truetype');
}
@font-face {
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/lexend/v19/wlptgwvFAVdoq2_F94zlCfv0bz1WC9wR_LA.ttf) format('truetype');
}
@font-face {
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/lexend/v19/wlptgwvFAVdoq2_F94zlCfv0bz1WC7sR_LA.ttf) format('truetype');
}
@font-face {
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/lexend/v19/wlptgwvFAVdoq2_F94zlCfv0bz1WC5IR_LA.ttf) format('truetype');
}
@font-face {
  font-family: 'Maven Pro';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/mavenpro/v36/7Auup_AqnyWWAxW2Wk3swUz56MS91Eww8SX25nA.ttf) format('truetype');
}
@font-face {
  font-family: 'Maven Pro';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/mavenpro/v36/7Auup_AqnyWWAxW2Wk3swUz56MS91Eww8Rf25nA.ttf) format('truetype');
}
@font-face {
  font-family: 'Maven Pro';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/mavenpro/v36/7Auup_AqnyWWAxW2Wk3swUz56MS91Eww8fvx5nA.ttf) format('truetype');
}
@font-face {
  font-family: 'Maven Pro';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/mavenpro/v36/7Auup_AqnyWWAxW2Wk3swUz56MS91Eww8cLx5nA.ttf) format('truetype');
}
@font-face {
  font-family: 'Maven Pro';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/mavenpro/v36/7Auup_AqnyWWAxW2Wk3swUz56MS91Eww8aXx5nA.ttf) format('truetype');
}
@font-face {
  font-family: 'Maven Pro';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/mavenpro/v36/7Auup_AqnyWWAxW2Wk3swUz56MS91Eww8Yzx5nA.ttf) format('truetype');
}
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v13/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuLyeMZg.ttf) format('truetype');
}
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v13/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuDyfMZg.ttf) format('truetype');
}
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v13/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuOKfMZg.ttf) format('truetype');
}
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v13/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuLyfMZg.ttf) format('truetype');
}
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v13/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuI6fMZg.ttf) format('truetype');
}
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v13/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuGKYMZg.ttf) format('truetype');
}
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v13/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuFuYMZg.ttf) format('truetype');
}
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v13/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuDyYMZg.ttf) format('truetype');
}
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v13/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuBWYMZg.ttf) format('truetype');
}
@font-face {
  font-family: AlongSans;
  src: url('./Fonts/Alongsans.ttf');
}
@font-face {
  font-family: AlongSans;
  src: url('./Fonts/AlongSansExtraBold.ttf');
}
@font-face {
  font-family: AlongSans;
  src: url('./Fonts/AlongsansMedium.ttf');
}
@font-face {
  font-family: AlongSans;
  src: url('./Fonts/AlongSansSemiBold.ttf');
}
:root {
  --color-white: #fff;
  --color-dark: #363949;
  --color-light: rgba(132, 139, 200, 0.18);
  --color-dark-variant: #677483;
  --color-background: #f6f6f9;
  --box-shadow: 0 2rem 3rem var(--color-light);
}
.dark-theme-variables {
  --color-background: #181a1e;
  --color-white: #202528;
  --color-dark: #edeffd;
  --color-dark-variant: #a3bdcc;
  --color-light: rgba(0, 0, 0, 0.4);
  --box-shadow: 0 2rem 3rem var(--color-light);
}
@keyframes gradient1 {
  0% {
    background-size: 120% 120%;
    opacity: 1;
    background-position: 0% 50%;
  }
  50% {
    background-size: 140% 140%;
    opacity: 0.5;
    background-position: 100% 50%;
  }
  100% {
    background-size: 100% 100%;
    opacity: 1;
    background-position: 0% 50%;
  }
}
@keyframes gradient2 {
  0% {
    background-size: 100% 100%;
  }
  50% {
    background-size: 400% 400%;
  }
  100% {
    background-size: 100% 100%;
  }
}
body {
  font-family: 'Lexend', sans-serif;
}
.main-container {
  overflow: hidden;
}
a {
  text-decoration: none;
  color: #101828;
}
#header {
  height: 80px;
  z-index: 997;
  transition: all 0.5s;
  padding: 20px 0;
  background: #ffffff;
}
#header.header-scrolled {
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.3);
}
#header .logo a {
  display: flex;
  align-items: center;
}
#header .logo a img {
  padding: 0;
  margin: 7px 0;
  max-height: 38px;
}
#header .logo a span {
  background: linear-gradient(16deg, #682696 0%, #6A2694 0.3%, #94256D 8.22%, #B7244D 16.3%, #D22333 24.46%, #E52221 32.72%, #F02217 41.15%, #F42213 50%, #F8B04A 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-left: 1rem;
}
#header .navbar-toggler {
  border: none;
  background: none;
}
#header .navbar-toggler:focus {
  box-shadow: 0 0 0 0.25rem #E52221;
}
#header .navbar-toggler-icon {
  background-image: url("./Assets/mobile-menu.svg");
}
#header .main-nav {
  margin: 0;
  padding: 0;
  list-style: none;
  background: #ffffff;
}
#header .main-nav * {
  margin: 0;
  padding: 0;
  list-style: none;
}
#header .main-nav > ul > li {
  position: relative;
  white-space: nowrap;
  float: left;
}
#header .main-nav > ul > li a {
  color: #6D737A;
  display: block;
  position: relative;
  padding: 10px 35px;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}
#header .main-nav > ul > li a.login {
  color: #ffffff;
  font-family: 'Maven Pro', sans-serif;
  font-weight: 700;
  border-radius: 6px;
  background: linear-gradient(16deg, #682696 0%, #6A2694 0.3%, #94256D 8.22%, #B7244D 16.3%, #D22333 24.46%, #E52221 32.72%, #F02217 41.15%, #F42213 50%, #F8B04A 100%);
}
#header .main-nav > ul > li a.login:hover {
  animation: gradient1 1s ease;
}
#header .main-nav > ul > li.active a,
#header .main-nav > ul > li:hover a:not(.login) {
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  background-image: linear-gradient(16deg, #682696 0%, #6A2694 0.3%, #94256D 8.22%, #B7244D 16.3%, #D22333 24.46%, #E52221 32.72%, #F02217 41.15%, #F42213 50%, #F8B04A 100%);
}
@media screen and (min-width: 768px) {
  #header .main-nav > ul > li a.login {
    margin-left: 0;
  }
}
@media screen and (min-width: 992px) {
  #header .main-nav > ul > li a.login {
    margin-left: 20px;
  }
}
#footer {
  background: #000000;
  padding: 0 0 30px 0;
  color: #eee;
  font-size: 14px;
}
#footer .footer-top {
  background: #000000;
  padding: 60px 0 30px 0;
}
#footer .footer-top .row > div {
  margin-top: 30px;
}
#footer .footer-top h4 {
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  text-transform: uppercase;
  position: relative;
  margin: 0 0 20px 0;
  padding: 0;
  padding-bottom: 10px;
}
#footer .footer-top .footer-info h3 {
  font-size: 90px;
  margin: 0 0 20px 0;
  padding: 2px 0 2px 0;
  line-height: 1;
  font-family: "AlongSans", sans-serif;
  font-weight: 600;
  letter-spacing: -1px;
  background: linear-gradient(16deg, #682696 0%, #6A2694 0.3%, #94256D 8.22%, #B7244D 16.3%, #D22333 24.46%, #E52221 32.72%, #F02217 41.15%, #F42213 50%, #F8B04A 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
#footer .footer-top .footer-info p {
  font-size: 13px;
  line-height: 24px;
  margin-bottom: 0;
  color: #ecf5ff;
}
#footer .footer-top .footer-links ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
#footer .footer-top .footer-links ul li {
  padding: 8px 0;
}
#footer .footer-top .footer-links ul li:first-child {
  padding-top: 0;
}
#footer .footer-top .footer-links ul li a {
  color: #eee;
}
#footer .footer-top .footer-contact p {
  line-height: 26px;
}
#footer .footer-top .footer-contact .social-links a {
  font-size: 18px;
  display: inline-block;
  color: #ffffff;
  line-height: 1;
  padding: 8px 0;
  margin-right: 4px;
  border-radius: 50%;
  text-align: center;
  width: 36px;
  height: 36px;
  transition: 0.3s;
}
#footer .footer-top .footer-newsletter input[type="email"] {
  border: 0;
  padding: 6px 8px;
  width: 65%;
}
#footer .footer-top .footer-newsletter input[type="submit"] {
  background: linear-gradient(16deg, #682696 0%, #6A2694 0.3%, #94256D 8.22%, #B7244D 16.3%, #D22333 24.46%, #E52221 32.72%, #F02217 41.15%, #F42213 50%, #F8B04A 100%);
  border: 0;
  width: 35%;
  padding: 6px 0;
  text-align: center;
  color: #ffffff;
  transition: 0.3s;
  cursor: pointer;
}
#footer .copyright {
  text-align: center;
  padding-top: 30px;
}
#footer .copyright a {
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  background-image: linear-gradient(16deg, #682696 0%, #6A2694 0.3%, #94256D 8.22%, #B7244D 16.3%, #D22333 24.46%, #E52221 32.72%, #F02217 41.15%, #F42213 50%, #F8B04A 100%);
}
#intro {
  margin-top: 5em;
}
#intro .img-section {
  position: relative;
  display: flex;
  justify-content: flex-end;
}
#intro .img-section > * {
  opacity: 0;
  will-change: transform;
}
#intro .img-section > img {
  max-width: 100%;
}
#intro .img-section .img-programmer-bg {
  transition: all 700ms ease;
}
#intro .img-section .img-programmer {
  position: absolute;
  top: 0;
  left: 0;
  transition: all 700ms ease;
}
#intro .img-section .img-data-courses {
  position: absolute;
  top: 0;
  right: 0;
  transform: translateY(400px);
  transition: all 1000ms ease;
}
#intro .img-section .img-data-tutor {
  position: absolute;
  bottom: 0;
  right: 0;
  transform: translateX(800px);
  transition: all 300ms ease;
}
#intro .img-section.move-img-programmer .img-programmer-bg {
  opacity: 1;
  animation: moveit 700ms ease forwards;
  will-change: transform;
}
#intro .img-section.move-img-programmer .img-programmer {
  opacity: 1;
  animation: moveit 700ms ease forwards;
  will-change: transform;
}
#intro .img-section.move-img-programmer .img-data-courses {
  opacity: 1;
  will-change: transform;
  transform: translateY(0);
}
#intro .img-section.move-img-programmer .img-data-tutor {
  opacity: 1;
  will-change: transform;
  transform: translateX(0);
}
#intro .img-section .waves {
  opacity: 1;
  width: 410px;
  height: 410px;
  right: 0;
  position: absolute;
  bottom: -250px;
  z-index: -1;
}
#intro .img-section .waves > div {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  border-radius: 50%;
  border: 1px solid rgba(32, 180, 134, 0.25);
  animation-name: spread;
  animation-duration: 2.1s;
  animation-delay: 0.5;
  animation-iteration-count: infinite;
  animation-timing-function: ease-out;
  animation-fill-mode: backwards;
}
#intro .img-section .waves > div:nth-child(2) {
  animation-delay: 0.7s;
  animation-timing-function: linear;
}
#intro .img-section .waves > div:nth-child(3) {
  animation-delay: 1s;
  animation-timing-function: linear;
}
#intro .img-section .waves > div:nth-child(4) {
  animation-delay: 1.5s;
  animation-timing-function: linear;
}
#intro .text-section {
  position: relative;
}
#intro .text-section h1 {
  color: #101828;
  font-size: 64px;
  font-style: normal;
  font-weight: 500;
  line-height: 82px;
  /* 128.125% */
  letter-spacing: -0.5px;
  text-transform: capitalize;
}
#intro .text-section h1 span {
  background: linear-gradient(112deg, #682696 0%, #6A2694 0.3%, #94256D 8.22%, #B7244D 16.3%, #D22333 24.46%, #E52221 32.72%, #F02217 41.15%, #F42213 50%, #F8B04A 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
#intro .text-section h1 span.span-2 {
  background: linear-gradient(174deg, #682696 0%, #6A2694 0.3%, #94256D 8.22%, #B7244D 16.3%, #D22333 24.46%, #E52221 32.72%, #F02217 41.15%, #F42213 50%, #F8B04A 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
#intro .text-section h1 span.span-3 {
  background: linear-gradient(97deg, #682696 0%, #6A2694 0.3%, #94256D 8.22%, #B7244D 16.3%, #D22333 24.46%, #E52221 32.72%, #F02217 41.15%, #F42213 50%, #F8B04A 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
#intro .text-section p {
  color: #667085;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 180%;
  margin-top: 20px;
}
#intro .text-section .search-box {
  position: relative;
  margin-top: 3rem;
}
#intro .text-section .search-box input {
  width: 100%;
  height: 72px;
  border-radius: 8px;
  background-color: #ffffff;
  border: 0;
  padding: 0 30px;
  filter: drop-shadow(-4px -4px 44px rgba(0, 0, 0, 0.08));
}
#intro .text-section .search-box input::placeholder {
  color: #667085;
  opacity: 0.5;
}
#intro .text-section .search-box button {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  border: 0;
  text-indent: -4999px;
  width: 65px;
  background: url("./Assets/search.svg") center no-repeat;
}
#intro .text-section .objectives {
  margin-top: 2rem;
}
#intro .text-section .objectives ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
#intro .text-section .objectives ul li {
  display: flex;
  position: relative;
  white-space: nowrap;
  float: left;
}
#intro .text-section .objectives ul li img {
  padding-right: 1rem;
}
#intro .text-section .waves {
  opacity: 1;
  width: 410px;
  height: 410px;
  left: -100px;
  position: absolute;
  top: 0;
  z-index: -1;
}
#intro .text-section .waves > div {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  border-radius: 50%;
  border: 1px solid rgba(32, 180, 134, 0.25);
  animation-name: spread;
  animation-duration: 2.1s;
  animation-delay: 0.5;
  animation-iteration-count: infinite;
  animation-timing-function: ease-out;
  animation-fill-mode: backwards;
}
#intro .text-section .waves > div:nth-child(2) {
  animation-delay: 0.7s;
  animation-timing-function: linear;
}
#intro .text-section .waves > div:nth-child(3) {
  animation-delay: 1s;
  animation-timing-function: linear;
}
#intro .text-section .waves > div:nth-child(4) {
  animation-delay: 1.5s;
  animation-timing-function: linear;
}
@keyframes moveit {
  0% {
    transform: scale3d(0.96, 0.96, 1);
  }
  20% {
    transform: scale3d(1.1, 1.1, 1);
  }
  40% {
    transform: scale3d(0.98, 0.98, 1);
  }
  60% {
    transform: scale3d(1.05, 1.05, 1);
  }
  80% {
    transform: scale3d(1.01, 1.01, 1);
  }
  100% {
    transform: scale3d(1, 1, 1);
  }
}
@keyframes spread {
  0% {
    transform: scale(0);
    opacity: 1;
  }
  40% {
    transform: scale(0);
    opacity: 1;
  }
  100% {
    transform: scale(1);
  }
}
#learning-path {
  display: flex;
  flex-direction: column;
  min-height: 5rem;
}
#learning-path .title {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 3rem;
}
#learning-path .title p.small-title {
  color: #6941C6;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
#learning-path .title h2 {
  font-size: 36px;
  max-width: 50rem;
  font-style: normal;
  text-align: center;
  font-weight: 600;
  line-height: 44px;
  letter-spacing: -0.72px;
}
#learning-path .swiper {
  height: 18rem;
}
#learning-path .swiper .swiper-pagination-bullet {
  transition: all 0.2s ease-out;
}
#learning-path .swiper .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background: linear-gradient(16deg, #682696 0%, #6A2694 0.3%, #94256D 8.22%, #B7244D 16.3%, #D22333 24.46%, #E52221 32.72%, #F02217 41.15%, #F42213 50%, #F8B04A 100%);
  width: 40px;
  border-radius: 6px;
}
#learning-path .paths .path-item {
  display: flex;
  height: 80%;
  cursor: pointer;
  padding: 30px 40px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background: #ffffff;
  border-radius: 12px;
  border: 0.5px solid rgba(216, 216, 216, 0.4);
  box-shadow: 0px 6.11438751px 50.06088257px 0px rgba(0, 0, 0, 0.05);
  position: relative;
}
#learning-path .paths .path-item:hover {
  background: linear-gradient(16deg, #682696 0%, #6A2694 0.3%, #94256D 8.22%, #B7244D 16.3%, #D22333 24.46%, #E52221 32.72%, #F02217 41.15%, #F42213 50%, #F8B04A 100%);
  color: #ffffff;
  animation: gradient2 1s ease;
}
#learning-path .paths .path-item:hover p {
  color: #ffffff;
}
#learning-path .paths .path-item:hover button {
  background: #ffffff;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
#learning-path .paths .path-item:hover button:after {
  border-color: #ffffff;
}
#learning-path .paths .path-item .header {
  display: flex;
  align-items: center;
}
#learning-path .paths .path-item .header .img-wrapper {
  width: 48px;
  height: 48px;
  background-color: #FCE7F6;
  display: flex;
  margin-right: 1rem;
  justify-content: center;
  border-radius: 10px;
}
#learning-path .paths .path-item .header .img-wrapper img {
  margin: auto;
}
#learning-path .paths .path-item .header h4 {
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  letter-spacing: 0.48px;
  margin-bottom: 0;
}
#learning-path .paths .path-item p {
  color: #667085;
  margin-top: 1rem;
  margin-bottom: 1.5rem;
  font-family: 'Inter', sans-serif;
}
#learning-path .paths .path-item button {
  background: linear-gradient(112deg, #682696 0%, #6A2694 0.3%, #94256D 8.22%, #B7244D 16.3%, #D22333 24.46%, #E52221 32.72%, #F02217 41.15%, #F42213 50%, #F8B04A 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  position: absolute;
  border: none;
  bottom: 1rem;
}
#learning-path .paths .path-item button:after {
  border-bottom: 2px solid #F8B04A;
  border-right: 2px solid #F8B04A;
  content: "";
  display: inline-block;
  height: 10px;
  position: absolute;
  right: -30px;
  top: calc(50% - 4px);
  transform: rotate(-45deg);
  width: 10px;
}
#popular-courses .top-section {
  position: relative;
}
#popular-courses .top-section .title {
  display: flex;
  flex-direction: column;
}
#popular-courses .top-section .title p.small-title {
  color: #6941C6;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
#popular-courses .top-section .title h2 {
  font-size: 36px;
  max-width: 50rem;
  font-style: normal;
  font-weight: 600;
  line-height: 44px;
  letter-spacing: -0.72px;
}
#popular-courses .top-section .title p.desc {
  margin-top: 0.7rem;
  color: #667085;
}
#popular-courses .top-section .show-all-button {
  position: relative;
  background: #fff;
  background-clip: padding-box;
  border: solid 5px transparent;
  border-radius: 1em;
  margin-bottom: 3rem;
}
#popular-courses .top-section .show-all-button a {
  height: 50px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: space-around;
  background: linear-gradient(112deg, #682696 0%, #6A2694 0.3%, #94256D 8.22%, #B7244D 16.3%, #D22333 24.46%, #E52221 32.72%, #F02217 41.15%, #F42213 50%, #F8B04A 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
#popular-courses .top-section .show-all-button a:hover {
  background: linear-gradient(16deg, #682696 0%, #6A2694 0.3%, #94256D 8.22%, #B7244D 16.3%, #D22333 24.46%, #E52221 32.72%, #F02217 41.15%, #F42213 50%, #F8B04A 100%);
  border-radius: 10px;
  animation: gradient2 1s ease;
  background-clip: text;
  -webkit-background-clip: unset;
  -webkit-text-fill-color: #ffffff;
}
#popular-courses .top-section .show-all-button:before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  margin: -2px;
  border-radius: 13px;
  background: linear-gradient(16deg, #682696 0%, #6A2694 0.3%, #94256D 8.22%, #B7244D 16.3%, #D22333 24.46%, #E52221 32.72%, #F02217 41.15%, #F42213 50%, #F8B04A 100%);
}
#popular-courses .courses-list {
  position: relative;
}
#popular-courses .courses-list .courses-filter-wrapper .courses-filter {
  margin-bottom: 2rem;
  display: flex;
  overflow-x: scroll;
  position: relative;
  overflow-y: visible;
}
#popular-courses .courses-list .courses-filter-wrapper .courses-filter::-webkit-scrollbar {
  padding-top: 23px;
}
#popular-courses .courses-list .courses-filter-wrapper .courses-filter button {
  margin-right: 1.5rem;
  background: #ffffff;
  color: #101828;
  font-family: 'Maven Pro', sans-serif;
  border: 1px solid rgba(35, 15, 15, 0.2);
  border-radius: 100px;
  padding: 0.6rem 1.5rem;
  min-width: fit-content;
}
#popular-courses .courses-list .courses-filter-wrapper .courses-filter button:hover {
  color: #ffffff;
  background: linear-gradient(16deg, #682696 0%, #6A2694 0.3%, #94256D 8.22%, #B7244D 16.3%, #D22333 24.46%, #E52221 32.72%, #F02217 41.15%, #F42213 50%, #F8B04A 100%);
  animation: gradient2 1s ease;
  border-color: #ffffff;
}
#popular-courses .courses-list .courses .course-item .course-inner-item {
  margin: 1.5rem 1rem;
  border-radius: 8px;
  padding: 1rem;
  box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.08), 0px 12px 16px -4px rgba(16, 24, 40, 0.03);
  cursor: pointer;
  transition: all 0.1s ease-in;
}
#popular-courses .courses-list .courses .course-item .course-inner-item:hover {
  box-shadow: 0px 53px 179px 101px rgba(209, 35, 50, 0.03), 0px 12px 25px 29px rgba(248, 176, 74, 0.08);
}
#popular-courses .courses-list .courses .course-item .course-inner-item:hover h5:before {
  animation: gradient1 700ms ease forwards;
}
#popular-courses .courses-list .courses .course-item .course-inner-item img {
  max-width: 100%;
  height: auto;
  border-radius: 4px;
}
#popular-courses .courses-list .courses .course-item .course-inner-item p.category {
  color: #6941C6;
  margin-top: 2rem;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}
#popular-courses .courses-list .courses .course-item .course-inner-item h5 {
  height: 65px;
  overflow: hidden;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  padding-right: 10%;
  position: relative;
}
#popular-courses .courses-list .courses .course-item .course-inner-item h5:before {
  content: "";
  display: inline-block;
  height: 25px;
  position: absolute;
  right: -30px;
  top: 5px;
  right: 0;
  width: 25px;
  background: url("./Assets/arrow-up-right.svg") center no-repeat;
  transition: all 700ms ease;
}
#popular-courses .courses-list .courses .course-item .course-inner-item p.desc {
  font-family: 'Inter', sans-serif;
  color: #667085;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  min-height: 75px;
}
@media screen and (min-width: 768px) {
  #popular-courses .top-section .title {
    margin-bottom: 3rem;
  }
  #popular-courses .top-section .show-all-button {
    position: absolute;
    right: 0;
    top: 40px;
  }
  #popular-courses .top-section .show-all-button a {
    width: 150px;
  }
  #popular-courses .courses-list .courses-filter-wrapper::after {
    content: "";
    display: block;
    height: 10px;
    position: absolute;
    right: 0;
    top: -10px;
    height: 70px;
    width: 200px;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #FFF 85.49%);
  }
}
#consult {
  background: linear-gradient(19deg, #682696 0%, #6A2694 0.3%, #94256D 8.22%, #B7244D 16.3%, #D22333 24.46%, #E52221 32.72%, #F02217 41.15%, #F42213 50%, #F8B04A 100%);
  position: relative;
}
#consult .img-section {
  position: relative;
  opacity: 0.1;
  z-index: 1;
}
#consult .img-section img {
  position: absolute;
  top: -130px;
  right: 0;
}
#consult .img-section::before {
  content: "";
  display: inline-block;
  height: 570px;
  position: absolute;
  top: -165px;
  right: -45px;
  width: 570px;
  background: url('./Assets/consult-img-bg.png') center top no-repeat;
  border-radius: 50%;
}
#consult .text-section {
  z-index: 2;
}
#consult .text-section h3 {
  font-family: 'Maven Pro', sans-serif;
  color: #ffffff;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 135%;
  text-transform: capitalize;
}
#consult .text-section .show-all-button {
  width: 150px;
  height: 64px;
  background-color: #ffffff;
  border-radius: 10px;
  margin-top: 3rem;
}
#consult .text-section .show-all-button a {
  width: 150px;
  height: 64px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: space-around;
  background: #ffffff linear-gradient(112deg, #682696 0%, #6A2694 0.3%, #94256D 8.22%, #B7244D 16.3%, #D22333 24.46%, #E52221 32.72%, #F02217 41.15%, #F42213 50%, #F8B04A 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
#consult .text-section .show-all-button a:hover {
  background: linear-gradient(16deg, #682696 0%, #6A2694 0.3%, #94256D 8.22%, #B7244D 16.3%, #D22333 24.46%, #E52221 32.72%, #F02217 41.15%, #F42213 50%, #F8B04A 100%);
  border-radius: 10px;
  animation: gradient2 1s ease;
  background-clip: text;
  -webkit-background-clip: unset;
  -webkit-text-fill-color: #ffffff;
}
@media screen and (min-width: 992px) {
  #consult .img-section {
    opacity: 1;
  }
}
#feedback {
  margin-top: 7rem;
  display: flex;
  flex-direction: column;
}
#feedback .title {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 3rem;
}
#feedback .title h2 {
  font-size: 36px;
  max-width: 50rem;
  font-style: normal;
  text-align: center;
  font-weight: 600;
  line-height: 44px;
  letter-spacing: -0.72px;
}
#feedback .swiper {
  height: 25rem;
}
#feedback .swiper .swiper-pagination-bullet {
  transition: all 0.2s ease-out;
}
#feedback .swiper .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background: linear-gradient(16deg, #682696 0%, #6A2694 0.3%, #94256D 8.22%, #B7244D 16.3%, #D22333 24.46%, #E52221 32.72%, #F02217 41.15%, #F42213 50%, #F8B04A 100%);
  width: 40px;
  border-radius: 6px;
}
#feedback .feedbacks .feedback-item {
  display: flex;
  height: 80%;
  padding: 30px 40px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background: #ffffff;
  border-radius: 12px;
  box-shadow: 0px 6.11438751px 50.06088257px 0px rgba(0, 0, 0, 0.05);
  position: relative;
}
#feedback .feedbacks .feedback-item:before {
  content: "";
  display: inline-block;
  height: 33px;
  position: absolute;
  top: 25px;
  right: 25px;
  width: 45px;
  background: url("./Assets/quote.svg") center no-repeat;
  transition: all 700ms ease;
}
#feedback .feedbacks .feedback-item:hover {
  background: linear-gradient(16deg, #682696 0%, #6A2694 0.3%, #94256D 8.22%, #B7244D 16.3%, #D22333 24.46%, #E52221 32.72%, #F02217 41.15%, #F42213 50%, #F8B04A 100%);
  color: #ffffff;
  animation: gradient2 1s ease;
}
#feedback .feedbacks .feedback-item:hover p,
#feedback .feedbacks .feedback-item:hover p.testimony {
  color: #ffffff;
}
#feedback .feedbacks .feedback-item .header {
  display: flex;
  align-items: center;
}
#feedback .feedbacks .feedback-item .header .img-wrapper {
  display: flex;
  margin-right: 1rem;
  justify-content: center;
  border-radius: 10px;
}
#feedback .feedbacks .feedback-item .header .img-wrapper img {
  margin: auto;
}
#feedback .feedbacks .feedback-item .header .header-text h6 {
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: 0.048px;
}
#feedback .feedbacks .feedback-item .header .header-text p {
  margin-bottom: 0;
}
#feedback .feedbacks .feedback-item p.testimony {
  color: #667085;
  margin-top: 1rem;
  margin-bottom: 1.5rem;
  font-family: 'Inter', sans-serif;
}
#about-head {
  margin-top: 6rem;
}
#about-head .img-section {
  position: relative;
  display: flex;
  justify-content: flex-start;
}
#about-head .img-section > * {
  opacity: 0;
  will-change: transform;
}
#about-head .img-section > img {
  max-width: 100%;
}
#about-head .img-section .img-ground-black {
  position: absolute;
  bottom: 0;
  left: 0;
  transform: translateX(800px);
  transition: all 300ms ease;
}
#about-head .img-section .img-ground-grey {
  position: absolute;
  bottom: 0;
  left: 0;
  transform: translateX(800px);
  transition: all 300ms ease;
}
#about-head .img-section .img-papers {
  position: absolute;
  top: 44px;
  right: 260px;
  transform: translateX(800px);
  transition: all 300ms ease;
}
#about-head .img-section .img-table {
  transition: all 700ms ease;
  margin-right: 140px;
  margin-top: 160px;
}
#about-head .img-section .img-decoration {
  position: absolute;
  bottom: 0;
  right: 0;
  transition: all 700ms ease;
}
#about-head .img-section .img-programmer {
  position: absolute;
  bottom: 0;
  right: 10%;
  transform: translateX(4000px);
  transition: all 1000ms ease;
}
#about-head .img-section .img-emp-eth {
  position: absolute;
  bottom: 30px;
  left: -220px;
  transform: translateX(-4000px);
  transition: all 1000ms ease;
}
#about-head .img-section.move-img-programmer .img-ground-black {
  opacity: 1;
  will-change: transform;
  transform: translateY(0);
}
#about-head .img-section.move-img-programmer .img-ground-grey {
  opacity: 1;
  will-change: transform;
  transform: translateY(0);
}
#about-head .img-section.move-img-programmer .img-papers {
  opacity: 1;
  will-change: transform;
  transform: translateY(0);
}
#about-head .img-section.move-img-programmer .img-table {
  opacity: 1;
  animation: moveit 700ms ease forwards;
  will-change: transform;
}
#about-head .img-section.move-img-programmer .img-decoration {
  opacity: 1;
  animation: moveit 700ms ease forwards;
  will-change: transform;
}
#about-head .img-section.move-img-programmer .img-programmer,
#about-head .img-section.move-img-programmer .img-emp-eth {
  opacity: 1;
  will-change: transform;
  transform: translateX(0);
}
#about-head .img-section .waves {
  opacity: 1;
  width: 750px;
  height: 750px;
  right: 0;
  position: absolute;
  top: -250px;
  right: -500px;
  z-index: -1;
}
#about-head .img-section .waves > div {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  border-radius: 50%;
  border: 1px solid rgba(32, 180, 134, 0.25);
  animation-name: spread;
  animation-duration: 2.1s;
  animation-delay: 0.5;
  animation-iteration-count: infinite;
  animation-timing-function: ease-out;
  animation-fill-mode: backwards;
}
#about-head .img-section .waves > div:nth-child(2) {
  animation-delay: 0.7s;
  animation-timing-function: linear;
}
#about-head .img-section .waves > div:nth-child(3) {
  animation-delay: 1s;
  animation-timing-function: linear;
}
#about-head .img-section .waves > div:nth-child(4) {
  animation-delay: 1.5s;
  animation-timing-function: linear;
}
#about-head .text-section {
  position: relative;
}
#about-head .text-section h1 {
  font-size: 35px;
  font-style: normal;
  font-weight: 700;
  line-height: 52px;
}
@media screen and (min-width: 576px) {
  #about-head {
    margin-top: 1em;
  }
  #about-head .text-section h1 {
    width: 540px;
    font-size: 40px;
  }
  #about-head .img-section.move-img-programmer .img-programmer {
    right: 55%;
  }
}
#stats {
  position: relative;
}
#stats .stat-item p.num {
  margin-top: 1.5rem;
  font-size: 34px;
  font-style: normal;
  font-weight: 400;
  line-height: 40.8px;
  background: linear-gradient(97deg, #682696 0%, #6A2694 0.3%, #94256D 8.22%, #B7244D 16.3%, #D22333 24.46%, #E52221 32.72%, #F02217 41.15%, #F42213 50%, #F8B04A 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
#stats .stat-item p.title {
  color: rgba(3, 31, 66, 0.66);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 19.6px;
  letter-spacing: 3px;
  text-transform: uppercase;
}
#stats .stat-item#stat-item-1 img {
  margin-right: -2rem;
}
#stats .stat-item#stat-item-2 img {
  margin-right: -1rem;
}
#stats .waves {
  opacity: 1;
  width: 750px;
  height: 750px;
  position: absolute;
  top: -250px;
  left: -500px;
  z-index: -1;
}
#stats .waves > div {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  border-radius: 50%;
  border: 1px solid rgba(32, 180, 134, 0.25);
  animation-name: spread;
  animation-duration: 2.1s;
  animation-delay: 0.5;
  animation-iteration-count: infinite;
  animation-timing-function: ease-out;
  animation-fill-mode: backwards;
}
#stats .waves > div:nth-child(2) {
  animation-delay: 0.7s;
  animation-timing-function: linear;
}
#stats .waves > div:nth-child(3) {
  animation-delay: 1s;
  animation-timing-function: linear;
}
#stats .waves > div:nth-child(4) {
  animation-delay: 1.5s;
  animation-timing-function: linear;
}
@media screen and (min-width: 576px) {
  #stats .stat-item {
    margin-bottom: 8rem;
  }
}
#about-video {
  margin-top: 5rem;
}
#about-video .img-section {
  position: relative;
  display: flex;
  justify-content: center;
}
#about-video .img-section > * {
  opacity: 0;
  will-change: transform;
}
#about-video .img-section > img {
  max-width: 100%;
}
#about-video .img-section .img-coin {
  position: absolute;
  top: 0;
  left: 30px;
  transform: translateY(-50px);
  transition: all 1000ms ease infinite;
  animation-duration: 1s;
  animation-name: upDown;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}
#about-video .img-section .img-guy {
  transition: all 700ms ease;
  z-index: 1;
}
#about-video .img-section .img-ground-coins {
  position: absolute;
  bottom: 3px;
  left: 10%;
  transform: translateX(800px);
  transition: all 300ms ease;
}
#about-video .img-section .img-bg {
  position: absolute;
  bottom: 0;
  left: -30px;
  transform: translateX(800px);
  transition: all 300ms ease;
}
#about-video .img-section.move-img-rich-guy .img-coin {
  opacity: 1;
}
#about-video .img-section.move-img-rich-guy .img-guy {
  opacity: 1;
  animation: moveit 700ms ease forwards;
  will-change: transform;
}
#about-video .img-section.move-img-rich-guy .img-ground-coins {
  opacity: 1;
  will-change: transform;
  transform: translateY(0);
}
#about-video .img-section.move-img-rich-guy .img-bg {
  opacity: 1;
  will-change: transform;
  transform: translateY(0);
}
#about-video .text-section h3 {
  font-size: 64px;
  font-style: normal;
  font-weight: 700;
  line-height: 96px;
  background: linear-gradient(97deg, #682696 0%, #6A2694 0.3%, #94256D 8.22%, #B7244D 16.3%, #D22333 24.46%, #E52221 32.72%, #F02217 41.15%, #F42213 50%, #F8B04A 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
#about-video .text-section h3 span {
  font-size: 48px;
  font-style: normal;
  font-weight: 400;
  line-height: 96px;
}
#about-video .text-section button {
  text-indent: -5500px;
  background: url('./Assets/about-us-video-play.png') no-repeat;
  background-position: 34px 50%;
  border-radius: 50%;
  border: none;
  width: 80px;
  height: 80px;
  position: relative;
}
#about-video .text-section button::before {
  content: "";
  display: inline-block;
  height: 80px;
  position: absolute;
  top: 0;
  right: 0;
  width: 80px;
  background: linear-gradient(16deg, #682696 0%, #6A2694 0.3%, #94256D 8.22%, #B7244D 16.3%, #D22333 24.46%, #E52221 32.72%, #F02217 41.15%, #F42213 50%, #F8B04A 100%);
  border-radius: 50%;
  z-index: -1;
}
@media screen and (min-width: 576px) {
  #about-video .img-section {
    justify-content: flex-start;
  }
  #about-video .img-section.move-img-rich-guy .img-ground-coins {
    left: 0;
  }
}
@keyframes upDown {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(300);
  }
}
#director-message {
  margin-top: 3rem;
}
#director-message .img-section img {
  max-width: 100%;
}
#director-message .text-section {
  position: relative;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
#director-message .text-section .small-text {
  background: linear-gradient(97deg, #682696 0%, #6A2694 0.3%, #94256D 8.22%, #B7244D 16.3%, #D22333 24.46%, #E52221 32.72%, #F02217 41.15%, #F42213 50%, #F8B04A 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
#director-message .text-section h3 {
  font-size: 64px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
#director-message .text-section p.message {
  color: #667085;
  margin-top: 3rem;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
#director-message .text-section img.signature {
  bottom: 10px;
  left: 0;
}
@media screen and (min-width: 576px) {
  #director-message .text-section p.message {
    max-width: 80%;
  }
  #director-message .text-section img.signature {
    position: absolute;
    bottom: 10px;
    left: 0;
  }
}
#contct-us .bitapps-location-map {
  padding: 0;
}
#contct-us .bitapps-location-map .map {
  width: 100%;
  height: 30em;
  margin: 0;
  padding: 0;
}
#contact-us-text {
  margin-top: 10rem;
}
#contact-us-text h2 {
  text-align: center;
  margin-bottom: 6rem;
  font-size: 2rem;
  font-style: normal;
  font-weight: 700;
  line-height: 2.8rem;
}
#contact-us-text .row {
  justify-content: space-around;
}
#contact-us-text .row > div {
  max-width: 300px;
}
#contact-us-text .row > div p.title {
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.4625rem;
  margin-bottom: 1rem;
}
#contact-us-text .row > div p {
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.925rem;
  margin-bottom: 0;
}
#contact-us-text .row > div p span {
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 700;
}
#contact-us-form .title {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 3rem;
}
#contact-us-form .title h2 {
  font-size: 36px;
  max-width: 50rem;
  font-style: normal;
  text-align: center;
  font-weight: 600;
  line-height: 44px;
  letter-spacing: -0.72px;
}
#contact-us-form form {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
#contact-us-form form .top {
  width: 100%;
  display: flex;
  height: 100%;
  flex-direction: row;
  justify-content: center;
}
#contact-us-form form .top input {
  width: 50%;
  max-width: 23rem;
  border-radius: 0.3125rem;
  border: 1px solid #F8F8F8;
  background: #F8F8F8;
  padding: 1.03125rem 1.3125rem;
  justify-content: center;
  align-items: center;
  margin: 0 1rem;
}
#contact-us-form form .bottom {
  width: 100%;
  display: flex;
  height: 100%;
  flex-direction: row;
  justify-content: center;
  position: relative;
  margin-bottom: 7rem;
}
#contact-us-form form .bottom textarea {
  width: 100%;
  max-width: 48rem;
  border-radius: 0.3125rem;
  border: 1px solid #F8F8F8;
  background: #F8F8F8;
  padding: 1.03125rem 1.3125rem;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  height: 12rem;
}
#contact-us-form form .bottom a {
  display: block;
  position: relative;
  padding: 10px 35px;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  position: absolute;
  color: #ffffff;
  font-family: 'Maven Pro', sans-serif;
  font-weight: 700;
  border-radius: 6px;
  background: linear-gradient(16deg, #682696 0%, #6A2694 0.3%, #94256D 8.22%, #B7244D 16.3%, #D22333 24.46%, #E52221 32.72%, #F02217 41.15%, #F42213 50%, #F8B04A 100%);
  bottom: -70px;
  width: 11rem;
}
#login-public .logo a {
  display: flex;
  align-items: center;
}
#login-public .logo a img {
  padding: 0;
  margin: 7px 0;
  max-height: 38px;
}
#login-public .logo a span {
  background: linear-gradient(16deg, #682696 0%, #6A2694 0.3%, #94256D 8.22%, #B7244D 16.3%, #D22333 24.46%, #E52221 32.72%, #F02217 41.15%, #F42213 50%, #F8B04A 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-left: 1rem;
}
#login-public .form-section {
  margin: auto;
  background: #ffffff;
  border-radius: 12px;
  border: 0.5px solid rgba(216, 216, 216, 0.4);
  box-shadow: 0px 6.11438751px 50.06088257px 0px rgba(0, 0, 0, 0.05);
  position: relative;
}
#login-public .form-section h3 {
  color: #6941C6;
  font-size: 26px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
#login-public .form-section h1 {
  font-size: 36px;
  max-width: 50rem;
  font-style: normal;
  font-weight: 600;
  line-height: 44px;
  letter-spacing: -0.72px;
}
#login-public .form-section h1 span {
  background: linear-gradient(16deg, #682696 0%, #6A2694 0.3%, #94256D 8.22%, #B7244D 16.3%, #D22333 24.46%, #E52221 32.72%, #F02217 41.15%, #F42213 50%, #F8B04A 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
#login-public .form-section form {
  margin-top: 2rem;
}
#login-public .form-section form .input-section input {
  width: 100%;
  border-radius: 0.3125rem;
  border: 1px solid #F8F8F8;
  background: #F8F8F8;
  padding: 1.03125rem 1.3125rem;
  justify-content: center;
  align-items: center;
  margin: 0.5rem 0;
}
#login-public .form-section form .auth-options {
  margin-top: 1rem;
}
#login-public .form-section form .auth-options .remember-me {
  display: flex;
}
#login-public .form-section form .auth-options .remember-me input {
  margin-right: 0.7rem;
}
#login-public .form-section form .auth-options .remember-me label {
  line-height: 1.5;
}
#login-public .form-section form .auth-options .forgot-password a:hover {
  text-decoration: underline;
}
#login-public .form-section form .button {
  margin-top: 5rem;
}
#login-public .form-section form .button button {
  color: #ffffff;
  font-family: 'Maven Pro', sans-serif;
  font-weight: 700;
  border-radius: 6px;
  background: linear-gradient(16deg, #682696 0%, #6A2694 0.3%, #94256D 8.22%, #B7244D 16.3%, #D22333 24.46%, #E52221 32.72%, #F02217 41.15%, #F42213 50%, #F8B04A 100%);
  border: none;
  width: 100%;
  height: 60px;
}
#login-public .form-section form .button button:hover {
  background: linear-gradient(16deg, #682696 0%, #6A2694 0.3%, #94256D 8.22%, #B7244D 16.3%, #D22333 24.46%, #E52221 32.72%, #F02217 41.15%, #F42213 50%, #F8B04A 100%);
  animation: gradient2 1s ease;
}
#login-public .form-section form .error-message {
  color: red;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.5px;
  margin-top: 1rem;
  text-align: center;
}
#login-public .img-section {
  position: relative;
}
#login-public .img-section img {
  max-width: 100%;
}
#login-public .img-section p {
  font-size: 34px;
  font-style: normal;
  font-weight: 500;
  line-height: 82px;
  letter-spacing: -0.5px;
  position: absolute;
  top: 46%;
  left: 56%;
  transform: translate(-50%, -50%);
  background: linear-gradient(16deg, #682696 0%, #6A2694 0.3%, #94256D 8.22%, #B7244D 16.3%, #D22333 24.46%, #E52221 32.72%, #F02217 41.15%, #F42213 50%, #F8B04A 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: moveit 700ms ease infinite;
}
#admin-dashboard {
  width: 100vw;
  height: 100vh;
  font-family: poppins, sans-serif !important;
  font-size: 0.88rem;
  background: var(--color-background);
  color: var(--color-dark);
  overflow-x: hidden;
}
#admin-dashboard .container {
  display: grid;
  width: 96%;
  margin: 0 auto;
  gap: 1.8rem;
  grid-template-columns: 14rem auto 23rem;
}
#admin-dashboard .container .main-container {
  margin-top: 4.4rem;
}
#admin-dashboard a {
  color: var(--color-dark);
}
#admin-dashboard img {
  display: block;
  width: 100%;
}
#admin-dashboard h1 {
  font-weight: 800;
  font-size: 1.8rem;
}
#admin-dashboard h2 {
  font-size: 1.4rem;
}
#admin-dashboard h3 {
  font-size: 0.87rem;
  margin-bottom: 0;
}
#admin-dashboard h4 {
  font-size: 0.8rem;
}
#admin-dashboard h5 {
  font-size: 0.77rem;
}
#admin-dashboard small {
  font-size: 0.75rem;
}
#admin-dashboard .profile-photo {
  width: 2.8rem;
  height: 2.8rem;
  border-radius: 50%;
  overflow: hidden;
}
#admin-dashboard .text-muted {
  color: #7d8da1;
}
#admin-dashboard p {
  color: var(--color-dark-variant);
}
#admin-dashboard b {
  color: var(--color-dark);
}
#admin-dashboard .primary {
  color: #7380ec;
}
#admin-dashboard .danger {
  color: #ff7782;
}
#admin-dashboard .success {
  color: #41f1b6;
}
#admin-dashboard .warning {
  color: #ffbb55;
}
#admin-dashboard #admin-dashboard-main {
  display: grid;
  width: 96%;
  margin: 0 auto;
  gap: 1.8rem;
  grid-template-columns: 14rem auto;
}
#admin-dashboard #admin-dashboard-main .admin-middle-view {
  margin-top: 5rem;
  overflow: scroll;
}
#admin-dashboard .back-button {
  margin-top: 4em;
  position: relative;
}
#admin-dashboard .back-button svg {
  position: absolute;
  right: 0;
  left: -120px;
  margin: auto;
  transition: all 300ms ease;
}
#admin-dashboard .back-button:hover svg {
  left: -160px;
}
#admin-dashboard .card-box {
  background: var(--color-white);
  padding: 1.8rem;
  border-radius: 2rem;
  margin-top: 1rem;
  box-shadow: var(--box-shadow);
  transition: all 300ms ease;
  margin-bottom: 100px;
}
#admin-dashboard .card-box:hover {
  box-shadow: none;
}
#admin-dashboard .card-report {
  background: var(--color-white);
  padding: 1.8rem;
  border-radius: 2rem;
  margin-top: 1rem;
  box-shadow: var(--box-shadow);
  transition: all 300ms ease;
}
#admin-dashboard .card-report:hover {
  box-shadow: none;
}
#admin-dashboard .card-report span svg {
  background: #7380ec;
  padding: 0.5rem;
  border-radius: 50%;
  color: var(--color-white);
  font-size: 3rem;
}
#admin-dashboard .card-report.expenses span svg {
  background: #ff7782;
}
#admin-dashboard .card-report.income span svg {
  background: #41f1b6;
}
#admin-dashboard .card-report .middle {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
#admin-dashboard .card-report .middle .left h3 {
  margin: 1rem 0 0.6rem;
  font-size: 1rem;
}
#admin-dashboard .card-report .middle .progress {
  position: relative;
  width: 92px;
  height: 92px;
  border-radius: 50%;
}
#admin-dashboard .card-report .middle .progress svg {
  width: 7rem;
  height: 7rem;
}
#admin-dashboard .card-report .middle .progress svg circle {
  fill: none;
  stroke: #7380ec;
  stroke-width: 14;
  stroke-linecap: round;
  transform: translate(5px, 5px);
}
#admin-dashboard .card-report .middle .progress .number {
  position: absolute;
  bottom: 50%;
  top: 36%;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
}
#admin-dashboard .card-report.sales .middle svg circle {
  stroke-dasharray: 110;
  stroke-dashoffset: 92;
}
#admin-dashboard .card-report.expenses .middle svg circle {
  stroke-dasharray: 80;
  stroke-dashoffset: 20;
}
#admin-dashboard .card-report.income .middle svg circle {
  stroke-dasharray: 110;
  stroke-dashoffset: 35;
}
#admin-dashboard .custom-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 0.7rem;
  padding: 1.4rem 1.8rem;
  box-shadow: var(--box-shadow);
  transition: all 300ms ease;
  background: transparent;
  border: 1px dashed #7380ec;
  border-radius: 1.2rem;
  color: #7380ec;
  cursor: pointer;
  font-family: inherit;
}
#admin-dashboard .custom-button.danger {
  border: 1px dashed #ff7782;
  color: #ff7782;
}
#admin-dashboard .custom-button:hover {
  box-shadow: none;
}
#admin-dashboard .custom-button div {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
#admin-dashboard .custom-button div h3 {
  font-weight: 600;
}
#admin-dashboard .right-panel {
  padding-top: 1.4rem;
  height: 100vh;
  position: sticky;
  top: 0;
}
#admin-dashboard .right-panel .top {
  display: flex;
  justify-content: end;
  gap: 2rem;
}
#admin-dashboard .right-panel .top .menu-btn {
  display: none;
}
#admin-dashboard .right-panel .top .theme-toggler {
  background: var(--color-light);
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 1.6rem;
  width: 4.2rem;
  cursor: pointer;
  border-radius: 0.4em;
}
#admin-dashboard .right-panel .top .theme-toggler span {
  font-size: 1.2rem;
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
#admin-dashboard .right-panel .top .theme-toggler span svg {
  font-size: 1.2rem;
}
#admin-dashboard .right-panel .top .theme-toggler span.active {
  background: #7380ec;
  color: var(--color-white);
  border-radius: 0.4em;
}
#admin-dashboard .right-panel .profile {
  display: flex;
  gap: 2rem;
  text-align: right;
}
#admin-dashboard .right-panel .recent-updates {
  margin-top: 1rem;
}
#admin-dashboard .right-panel .recent-updates h2 {
  margin-bottom: 0.8rem;
}
#admin-dashboard .right-panel .recent-updates .updates {
  background: var(--color-white);
  padding: 1.8rem;
  border-radius: 2rem;
  box-shadow: var(--box-shadow);
  transition: all 300ms ease;
}
#admin-dashboard .right-panel .recent-updates .updates:hover {
  box-shadow: none;
}
#admin-dashboard .right-panel .recent-updates .updates .update {
  display: grid;
  grid-template-columns: 2.6rem auto;
  gap: 1rem;
  margin-bottom: 1rem;
}
#admin-dashboard .right-panel .sales-analytics {
  margin-top: 2rem;
}
#admin-dashboard .right-panel .sales-analytics h2 {
  margin-bottom: 0.8rem;
}
#admin-dashboard .right-panel .sales-analytics .item {
  background: var(--color-white);
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 0.7rem;
  padding: 1.4rem 1.8rem;
  border-radius: 1.2rem;
  box-shadow: var(--box-shadow);
  transition: all 300ms ease;
}
#admin-dashboard .right-panel .sales-analytics .item:hover {
  box-shadow: none;
}
#admin-dashboard .right-panel .sales-analytics .item .icon {
  padding: 0.6rem;
  color: var(--color-white);
  border-radius: 50%;
  background: #7380ec;
  display: flex;
}
#admin-dashboard .right-panel .sales-analytics .item.offline .icon {
  background: #ff7782;
}
#admin-dashboard .right-panel .sales-analytics .item.customers .icon {
  background: #41f1b6;
}
#admin-dashboard .right-panel .sales-analytics .item .right {
  display: flex;
  justify-content: space-between;
  align-items: start;
  margin: 0;
  width: 100%;
}
#admin-dashboard .right-panel .sales-analytics .item.add-product {
  background: transparent;
  border: 1px dashed #7380ec;
  color: #7380ec;
  display: flex;
  align-items: center;
  justify-content: center;
}
#admin-dashboard .right-panel .sales-analytics .item.add-product div {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
#admin-dashboard .right-panel .sales-analytics .item.add-product div h3 {
  font-weight: 600;
}
#admin-dashboard aside {
  height: 100vh;
  position: sticky;
  top: 0;
}
#admin-dashboard aside .top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1.4rem;
}
#admin-dashboard aside .top .logo {
  display: flex;
  gap: 0.8rem;
}
#admin-dashboard aside .top .logo img {
  max-height: 38px;
}
#admin-dashboard aside .top .close {
  display: none;
}
#admin-dashboard aside .sidebar {
  display: flex;
  flex-direction: column;
  height: 86vh;
  position: relative;
  top: 3rem;
}
#admin-dashboard aside .sidebar h3 {
  font-weight: 500;
}
#admin-dashboard aside .sidebar > a:last-child {
  position: absolute;
  bottom: 2rem;
  width: 100%;
}
#admin-dashboard aside .sidebar a {
  display: flex;
  color: #7d8da1;
  margin-left: 2rem;
  gap: 1rem;
  align-items: center;
  position: relative;
  height: 3.7rem;
  transition: all 300ms ease;
}
#admin-dashboard aside .sidebar a span {
  font-size: 1.6rem;
  transition: all 300ms ease;
}
#admin-dashboard aside .sidebar a.active {
  background: var(--color-light);
  color: #7380ec;
  margin-left: 0;
}
#admin-dashboard aside .sidebar a.active:before {
  content: "";
  width: 6px;
  height: 100%;
  background: #7380ec;
}
#admin-dashboard aside .sidebar a.active span {
  color: #7380ec;
  margin-left: calc(1rem - 3px);
}
#admin-dashboard aside .sidebar a:hover {
  color: #7380ec;
}
#admin-dashboard aside .sidebar a:hover span {
  margin-left: 1rem;
}
#admin-dashboard .mui-table {
  background: var(--color-white);
  width: 100%;
  border-radius: 2rem;
  padding: 1.8rem;
  text-align: center;
  box-shadow: var(--box-shadow);
  transition: all 300ms ease;
  color: var(--color-dark);
}
#admin-dashboard .mui-table:hover {
  box-shadow: none;
}
#admin-dashboard .mui-table .table-head {
  font-weight: bold;
  font-size: 1.1em;
  font-family: poppins, sans-serif !important;
}
#admin-dashboard .mui-table tr td {
  height: 2.8rem;
  border-bottom: 1px solid var(--color-light);
  font-size: 1rem;
  font-weight: 500;
}
#admin-dashboard .mui-table tr:last-child td {
  border-bottom: none;
}
#admin-dashboard .mui-table tr:hover {
  cursor: pointer;
}
#admin-dashboard .add-course-form,
#admin-dashboard .edit-course-form {
  display: flex;
  flex-direction: column;
}
#admin-dashboard .add-course-form > div,
#admin-dashboard .edit-course-form > div {
  margin-top: 2rem;
}
#admin-dashboard .add-course-form img,
#admin-dashboard .edit-course-form img {
  align-self: center;
  max-width: 500px;
  margin-bottom: 30px;
}
#admin-dashboard .upload-image-box {
  display: flex;
  flex-direction: column;
}
#admin-dashboard .upload-image-box .input-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
}
#admin-dashboard .upload-image-box .input-wrapper #raised-button-file {
  position: absolute;
  width: 100%;
  height: 100%;
}
#admin-dashboard .upload-image-box .input-wrapper label {
  padding-bottom: 90px;
}
#admin-dashboard .upload-image-box .input-wrapper svg {
  font-size: 60px;
}
#admin-dashboard .upload-image-box .input-wrapper img {
  max-width: 500px;
}
#admin-dashboard .add-language-form,
#admin-dashboard .edit-language-form {
  display: flex;
  flex-direction: column;
}
#admin-dashboard .add-language-form > div,
#admin-dashboard .edit-language-form > div {
  margin-bottom: 2rem;
}
#admin-dashboard .add-video-form,
#admin-dashboard .edit-video-form {
  display: flex;
  flex-direction: column;
}
#admin-dashboard .add-video-form > div,
#admin-dashboard .edit-video-form > div {
  margin-top: 2rem;
}
#admin-dashboard .add-video-form img,
#admin-dashboard .edit-video-form img {
  align-self: center;
  max-width: 500px;
  margin-bottom: 30px;
}
#admin-dashboard .add-video-form .course-picker,
#admin-dashboard .edit-video-form .course-picker,
#admin-dashboard .add-video-form .add-video-by-language,
#admin-dashboard .edit-video-form .add-video-by-language {
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
}
#admin-dashboard .add-video-form .course-picker .custom-button,
#admin-dashboard .edit-video-form .course-picker .custom-button,
#admin-dashboard .add-video-form .add-video-by-language .custom-button,
#admin-dashboard .edit-video-form .add-video-by-language .custom-button {
  margin-top: 1rem;
}
#admin-dashboard .add-video-by-language {
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
}
#admin-dashboard .add-video-by-language > div {
  margin-top: 2rem;
}
#admin-dashboard .add-video-by-language .custom-button {
  margin-top: 1rem;
}
#admin-dashboard .upload-image-box {
  display: flex;
  flex-direction: column;
}
#admin-dashboard .upload-image-box .input-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
}
#admin-dashboard .upload-image-box .input-wrapper #raised-button-file {
  position: absolute;
  width: 100%;
  height: 100%;
}
#admin-dashboard .upload-image-box .input-wrapper label {
  padding-bottom: 90px;
}
#admin-dashboard .upload-image-box .input-wrapper svg {
  font-size: 60px;
}
#admin-dashboard .upload-image-box .input-wrapper img {
  max-width: 500px;
}
#classroom-root {
  /* Old css start */
  /* ul.ant-menu.ant-menu-root.ant-menu-inline.ant-menu-light.left-nav li:hover {
    box-shadow: 0px 20px 27px #0000000d;
    border-radius: 8px;
    background: #fff;
    border: 0px;
    font-weight: 600;
  }
  
  ul.ant-menu.ant-menu-root.ant-menu-inline.ant-menu-light.left-nav
    li:hover
    span.anticon {
    background: #1890ff;
    color: #fff;
  } */
  /* .percent-progress .ant-progress-outer {
    margin-right: 0px;
    padding-right: 0px;
  } */
  /* sign up start */
  /* signin start csss */
  /* sign in end */
  /* end sign up */
  /* old css end */
}
#classroom-root .ant-layout.ant-layout-has-sider {
  flex-direction: row;
}
#classroom-root .layout-dashboard {
  background: transparent;
  text-align: left;
  position: relative;
  overflow-x: hidden;
}
@media (min-width: 992px) {
  #classroom-root .layout-dashboard {
    overflow: auto;
  }
}
#classroom-root .layout-dashboard .ant-layout-sider.sider-primary {
  width: 250px;
  position: fixed;
  left: 0;
  z-index: 99;
  height: 100vh;
  overflow: auto;
  margin: 0;
  padding: 33px 20px;
}
#classroom-root .layout-dashboard .ant-layout-sider.sider-primary .brand img {
  height: 60px;
}
#classroom-root .layout-dashboard .ant-layout-sider.sider-primary hr {
  margin: 18px 0;
  border: none;
  height: 1px;
  background-color: #f5f5f5;
}
#classroom-root .layout-dashboard .ant-layout-sider.sider-primary .brand {
  font-weight: 600;
}
#classroom-root .layout-dashboard .ant-layout-sider.sider-primary .brand span {
  vertical-align: middle;
  margin-left: 3px;
}
#classroom-root .layout-dashboard .ant-layout-sider.sider-primary .ant-layout-sider-children {
  width: auto;
}
#classroom-root .layout-dashboard .ant-layout-sider.sider-primary .ant-menu-inline {
  border: none;
}
#classroom-root .layout-dashboard .ant-menu {
  background: transparent;
}
#classroom-root .layout-dashboard .ant-layout-sider.sider-primary .ant-menu-inline .ant-menu-item,
#classroom-root .layout-dashboard .ant-layout-sider.sider-primary .ant-menu-inline .ant-menu-submenu {
  margin: 0;
  overflow: visible;
}
#classroom-root .layout-dashboard .ant-layout-sider.sider-primary .ant-menu-item,
#classroom-root .layout-dashboard .ant-layout-sider.sider-primary .ant-menu-submenu {
  padding: 0 !important;
  height: auto;
  line-height: normal;
}
#classroom-root .layout-dashboard .ant-layout-sider.sider-primary .ant-menu-inline .ant-menu-item:after,
#classroom-root .layout-dashboard .ant-layout-sider.sider-primary .ant-menu-inline .ant-menu-submenu:after {
  display: none;
}
#classroom-root .layout-dashboard .ant-layout-sider.sider-primary .ant-menu-item.ant-menu-item-selected,
#classroom-root .layout-dashboard .ant-layout-sider.sider-primary .ant-menu-item.ant-menu-item:active,
#classroom-root .layout-dashboard .ant-layout-sider.sider-primary .ant-menu-item .ant-menu-submenu-selected,
#classroom-root .layout-dashboard .ant-layout-sider.sider-primary .ant-menu-item.ant-menu-submenu-title:active,
#classroom-root .layout-dashboard .ant-layout-sider.sider-primary .ant-menu-item .ant-menu-submenu:active,
#classroom-root .layout-dashboard .ant-layout-sider.sider-primary .ant-menu-submenu.ant-menu-item-selected,
#classroom-root .layout-dashboard .ant-layout-sider.sider-primary .ant-menu-submenu.ant-menu-item:active,
#classroom-root .layout-dashboard .ant-layout-sider.sider-primary .ant-menu-submenu .ant-menu-submenu-selected,
#classroom-root .layout-dashboard .ant-layout-sider.sider-primary .ant-menu-submenu.ant-menu-submenu-title:active,
#classroom-root .layout-dashboard .ant-layout-sider.sider-primary .ant-menu-submenu .ant-menu-submenu:active {
  background-color: transparent;
}
#classroom-root .layout-dashboard .ant-layout-sider.sider-primary .ant-menu-item.ant-menu-item-selected .active,
#classroom-root .layout-dashboard .ant-layout-sider.sider-primary .ant-menu-item .active,
#classroom-root .layout-dashboard .ant-layout-sider.sider-primary .ant-menu-submenu.ant-menu-item-selected .active,
#classroom-root .layout-dashboard .ant-layout-sider.sider-primary .ant-menu-submenu .active {
  background-color: #fff;
  box-shadow: 0 20px 27px rgba(0, 0, 0, 0.05);
  font-weight: 600;
}
#classroom-root .layout-dashboard .ant-layout-sider.sider-primary .ant-menu-item a,
#classroom-root .layout-dashboard .ant-layout-sider.sider-primary .ant-menu-submenu a {
  padding: 10px 16px;
  color: #141414;
  border-radius: 8px;
  display: flex;
  align-items: center;
  white-space: nowrap;
}
#classroom-root .layout-dashboard .ant-layout-sider.sider-primary.ant-layout-sider-primary .ant-menu-item.ant-menu-item-selected .active .icon,
#classroom-root .layout-dashboard .ant-layout-sider.sider-primary.ant-layout-sider-primary .ant-menu-item .active .icon {
  background-color: #1890ff;
}
#classroom-root .layout-dashboard .ant-layout-sider.sider-primary .ant-menu-item.ant-menu-item-selected .active .icon,
#classroom-root .layout-dashboard .ant-layout-sider.sider-primary .ant-menu-item .active .icon,
#classroom-root .layout-dashboard .ant-layout-sider.sider-primary .ant-menu-submenu.ant-menu-item-selected .active .icon,
#classroom-root .layout-dashboard .ant-layout-sider.sider-primary .ant-menu-submenu .active .icon {
  background-color: #1890ff;
}
#classroom-root .layout-dashboard .ant-layout-sider.sider-primary .ant-menu-item .icon,
#classroom-root .layout-dashboard .ant-layout-sider.sider-primary .ant-menu-submenu .icon {
  display: inline-flex;
  width: 32px;
  height: 32px;
  background-color: #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.12);
  border-radius: 6px;
  justify-content: center;
  align-items: center;
  margin-right: 11px;
  vertical-align: middle;
}
#classroom-root .layout-dashboard .ant-layout-sider.sider-primary .ant-menu-item.ant-menu-item-selected .active svg path,
#classroom-root .layout-dashboard .ant-layout-sider.sider-primary .ant-menu-item .active svg path,
#classroom-root .layout-dashboard .ant-layout-sider.sider-primary .ant-menu-submenu.ant-menu-item-selected .active svg path,
#classroom-root .layout-dashboard .ant-layout-sider.sider-primary .ant-menu-submenu .active svg path {
  fill: #fff;
}
#classroom-root .layout-dashboard .ant-layout-sider.sider-primary .ant-menu-item svg path,
#classroom-root .layout-dashboard .ant-layout-sider.sider-primary .ant-menu-submenu svg path {
  fill: #bfbfbf;
}
#classroom-root .layout-dashboard .ant-layout-sider.sider-primary .ant-menu-item a:hover,
#classroom-root .layout-dashboard .ant-layout-sider.sider-primary .ant-menu-submenu a:hover {
  color: #141414;
}
#classroom-root .layout-dashboard .ant-layout-sider.sider-primary .ant-menu-inline .ant-menu-item.menu-item-header {
  padding: 10px 16px !important;
  color: #8c8c8c;
  font-weight: 700;
  font-size: 13px;
  text-transform: uppercase;
  display: block;
}
#classroom-root .menu-item-header:hover {
  color: inherit;
}
#classroom-root .layout-dashboard .ant-layout-sider.sider-primary.ant-layout-sider-zero-width {
  overflow-x: hidden;
  width: 210px;
  margin: 0;
  padding: 33px 0;
}
#classroom-root .layout-dashboard.has-sidebar .ant-layout-sider.sider-primary .aside-footer {
  display: block;
}
#classroom-root .layout-dashboard .ant-layout-sider.sider-primary.ant-layout-sider-primary .footer-box {
  background-color: #1890ff;
  color: #fff;
  box-shadow: none;
}
#classroom-root .layout-dashboard .ant-layout-sider.sider-primary .footer-box {
  background-color: #fff;
  color: #141414;
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0 20px 27px rgba(0, 0, 0, 0.05);
  margin-bottom: 8px;
}
#classroom-root .layout-dashboard .ant-layout-sider.sider-primary.ant-layout-sider-primary .footer-box .icon {
  background-color: #fff;
}
#classroom-root .layout-dashboard .ant-layout-sider.sider-primary .footer-box .icon {
  display: inline-flex;
  width: 32px;
  height: 32px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.12);
  border-radius: 6px;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
  background-color: #1890ff;
}
#classroom-root .layout-dashboard .ant-layout-sider.sider-primary.ant-layout-sider-primary .footer-box h6 {
  color: #fff;
}
#classroom-root .layout-dashboard .ant-layout-sider.sider-primary .footer-box h6 {
  font-weight: 600;
  font-size: 16px;
  color: #141414;
  margin-bottom: 0;
}
#classroom-root .layout-dashboard .ant-layout-sider.sider-primary.ant-layout-sider-primary .footer-box p {
  color: #f5f5f5;
}
#classroom-root .layout-dashboard .ant-layout-sider.sider-primary .footer-box p {
  color: #8c8c8c;
  font-weight: 600;
}
#classroom-root .layout-dashboard .ant-layout-sider.sider-primary.ant-layout-sider-primary .footer-box button {
  background-color: #fff;
  border-color: #f0f0f0;
  color: #141414;
}
#classroom-root .layout-dashboard .ant-layout-sider.sider-primary .footer-box button {
  margin: 0;
}
#classroom-root .ant-btn-sm,
#classroom-root .ant-radio-group-small .ant-radio-button-wrapper {
  height: 34px;
  font-size: 12px;
  padding: 0 15px;
}
#classroom-root .ant-btn-sm {
  border-radius: 4px;
}
#classroom-root .ant-btn-sm,
#classroom-root .ant-radio-group-small .ant-radio-button-wrapper {
  height: 34px;
  font-size: 12px;
  padding: 0 15px;
}
#classroom-root .ant-btn-sm {
  border-radius: 4px;
}
#classroom-root .ant-btn,
#classroom-root .ant-radio-group .ant-radio-button-wrapper {
  font-weight: 600;
}
#classroom-root .layout-dashboard .ant-layout-sider.sider-primary .aside-footer {
  padding-top: 100px;
  padding-bottom: 33px;
}
#classroom-root .ant-menu-inline.ant-menu-root .ant-menu-item,
#classroom-root .ant-menu-inline.ant-menu-root .ant-menu-submenu-title {
  display: block;
}
#classroom-root .ant-layout.ant-layout-has-sider > .ant-layout,
#classroom-root .ant-layout.ant-layout-has-sider > .ant-layout-content {
  overflow-x: hidden;
}
#classroom-root .layout-dashboard .ant-layout {
  background: transparent;
  position: relative;
  min-height: 100vh;
  width: 100%;
  flex-shrink: 0;
}
#classroom-root .layout-dashboard .ant-layout-header {
  background: transparent;
  height: auto;
  padding: 16px;
  margin: 10px 4px;
  line-height: inherit;
  border-radius: 12px;
  transition: 0.2s;
}
#classroom-root .layout-dashboard .ant-breadcrumb > span .ant-breadcrumb-link a {
  color: #8c8c8c;
}
#classroom-root .layout-dashboard .ant-breadcrumb > span:last-child .ant-breadcrumb-link {
  color: #141414;
}
#classroom-root .layout-dashboard .ant-page-header-heading {
  margin-top: 0;
}
#classroom-root .layout-dashboard .ant-page-header-heading .ant-page-header-heading-title {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  margin-top: 4px;
}
#classroom-root .layout-dashboard .header-control {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  margin-top: 10px;
}
#classroom-root .ant-dropdown-menu.ant-dropdown-menu-split.header-notifications-dropdown {
  background: #fff;
  box-shadow: 0 20px 27px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
  min-width: 276px;
  padding: 16px 8px;
}
#classroom-root .ant-dropdown-menu.ant-dropdown-menu-split.header-notifications-dropdown ul.ant-dropdown-menu-items {
  margin: 0px;
  padding: 0px;
}
#classroom-root .ant-dropdown.ant-dropdown-placement-bottomRight {
  min-width: inherit !important;
}
#classroom-root .ant-dropdown-menu.ant-dropdown-menu-split.header-notifications-dropdown .ant-list-item {
  padding: 5px 16px;
  margin-bottom: 8px;
}
#classroom-root .ant-dropdown-menu.ant-dropdown-menu-split.header-notifications-dropdown .ant-avatar {
  width: 36px;
  height: 36px;
  background-color: #fafafa;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.12);
  display: flex;
  justify-content: center;
  align-items: center;
}
#classroom-root .ant-dropdown-menu.ant-dropdown-menu-split.header-notifications-dropdown .ant-list-item-meta-title {
  line-height: normal;
}
#classroom-root .ant-dropdown-menu.ant-dropdown-menu-split.header-notifications-dropdownt .ant-list-item-meta-description {
  color: #8c8c8c;
  font-size: 12px;
  line-height: 12px;
  font-weight: 600;
}
#classroom-root .ant-dropdown-menu.ant-dropdown-menu-split.header-notifications-dropdown .ant-list-item-meta-description svg {
  width: 14px;
  height: 14px;
  fill: #8c8c8c;
  vertical-align: middle;
  margin-right: 4px;
}
#classroom-root .ant-dropdown-menu.ant-dropdown-menu-split.header-notifications-dropdown .ant-list-item-meta-description svg path {
  fill: #8c8c8c;
}
#classroom-root .ant-dropdown-menu.ant-dropdown-menu-split.header-notifications-dropdown .ant-list-item-meta-description span {
  vertical-align: middle;
}
#classroom-root .ant-dropdown-menu.ant-dropdown-menu-split.header-notifications-dropdown .ant-avatar .ant-avatar-string {
  top: 6px;
}
#classroom-root .ant-dropdown-menu.ant-dropdown-menu-split.header-notifications-dropdown .ant-avatar .ant-avatar-string svg {
  width: 22px;
  height: 22px;
}
#classroom-root .h3,
#classroom-root .h4,
#classroom-root h3,
#classroom-root h4 {
  font-weight: 700;
  color: #141414;
}
#classroom-root .ant-list-item-meta-title > a {
  color: rgba(0, 0, 0, 0.65);
  transition: all 0.3s;
}
#classroom-root .ant-list-item-meta-title {
  margin-bottom: 4px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  line-height: 22px;
}
#classroom-root .layout-dashboard .header-control .ant-btn-link {
  height: auto;
  padding: 0 7px;
  margin: 0;
  box-shadow: none;
  color: #8c8c8c;
  line-height: normal;
}
#classroom-root .ant-btn svg {
  margin-right: 5px;
}
#classroom-root .layout-dashboard .header-control svg {
  width: 20px;
  height: 20px;
}
#classroom-root .layout-dashboard .header-control svg path {
  fill: #141414;
}
#classroom-root .layout-dashboard .header-control .btn-sign-in {
  height: auto;
  padding: 0 7px;
  margin: 0;
  box-shadow: none;
  color: #8c8c8c;
}
#classroom-root .layout-dashboard .header-control .btn-sign-in svg {
  margin-right: 4px;
}
#classroom-root .layout-dashboard .header-control svg {
  vertical-align: middle;
}
#classroom-root .layout-dashboard .header-control .header-search {
  width: 213px;
  margin: 0 7px 0 0;
  border-radius: 6px;
  height: 40px;
  border: 1px solid #d9d9d9;
}
#classroom-root .layout-dashboard .header-control .anticon.anticon-search svg {
  width: 16px;
  height: 16px;
}
#classroom-root .layout-dashboard .header-control .header-search svg path {
  fill: #8c8c8c;
}
#classroom-root .settings-drawer .drawer-content > hr {
  margin: 18px 0;
  border: none;
  height: 1px;
  background-color: #f5f5f5;
}
#classroom-root .settings-drawer .drawer-content > h6 {
  font-size: 20px;
  margin-bottom: 0;
}
#classroom-root .layout-dashboard .settings-drawer .drawer-content h6 {
  font-weight: 600;
  color: #141414;
}
#classroom-root .settings-drawer .drawer-content p {
  color: #8c8c8c;
}
#classroom-root p {
  font-size: 14px;
}
#classroom-root .settings-drawer .drawer-content .sidebar-color h6 {
  margin-bottom: 8px;
}
#classroom-root .h6,
#classroom-root h6 {
  font-size: 16px;
}
#classroom-root .drawer-sidebar .ant-drawer-body {
  padding: 0px;
}
#classroom-root .drawer-sidebar .ant-drawer-body aside.ant-layout-sider.ant-layout-sider-light.sider-primary.ant-layout-sider-primary {
  margin: 0px;
}
#classroom-root .layout-dashboard .header-control .header-search .ant-input-suffix .ant-input-search-icon.anticon-loading,
#classroom-root .layout-dashboard .header-control .sidebar-toggler {
  display: block;
}
#classroom-root .layout-dashboard .header-control .btn-sign-in span {
  font-weight: 600;
  vertical-align: middle;
  display: none;
}
#classroom-root .site-layout-background {
  background: transparent;
}
#classroom-root header.ant-layout-header.subheader {
  background: transparent;
}
#classroom-root .sidemenu {
  margin-top: 20px;
  margin-left: 20px;
}
#classroom-root .logo img {
  width: 32px;
  margin-right: 10px;
}
#classroom-root .logo {
  margin-bottom: 15px;
}
#classroom-root .logo span {
  font-size: 16px;
  font-weight: 600;
}
#classroom-root hr.horizontal.dark {
  background-image: linear-gradient(90deg, transparent, rgba(0, 0, 0, 0.12), transparent);
  border: 0px;
}
#classroom-root hr.horizontal {
  background-color: transparent;
}
#classroom-root hr:not([size]) {
  height: 1px;
}
#classroom-root ul.ant-menu.ant-menu-root.ant-menu-inline.ant-menu-light.left-nav {
  background: transparent;
}
#classroom-root ul.ant-menu.ant-menu-root.ant-menu-inline.ant-menu-light.left-nav li {
  padding: 10px 16px !important;
  height: auto;
  line-height: normal;
  margin: 0px;
  border: 0px;
  font-size: 16px;
  color: #141414;
}
#classroom-root ul.ant-menu.ant-menu-root.ant-menu-inline.ant-menu-light.left-nav li.ant-menu-item-selected {
  box-shadow: 0px 20px 27px #0000000d;
  border-radius: 8px;
  background: #fff;
  border: 0px;
  font-weight: 600;
}
#classroom-root ul.ant-menu.ant-menu-root.ant-menu-inline.ant-menu-light.left-nav li.ant-menu-item-selected {
  box-shadow: 0px 20px 27px #0000000d;
  border-radius: 8px;
  background: #fff;
  border: 0px;
  font-weight: 600;
}
#classroom-root .ant-menu-inline.ant-menu-root .ant-menu-item > .ant-menu-title-content,
#classroom-root .ant-menu-inline.ant-menu-root .ant-menu-submenu-title > .ant-menu-title-content {
  flex: auto;
  min-width: 0;
  overflow: visible;
  text-overflow: ellipsis;
}
#classroom-root ul.ant-menu.ant-menu-root.ant-menu-inline.ant-menu-light.left-nav li::after {
  display: none;
}
#classroom-root ul.ant-menu.ant-menu-root.ant-menu-inline.ant-menu-light.left-nav li span.anticon {
  width: 32px;
  height: 32px;
  line-height: 32px;
  background: #fff;
  box-shadow: 0px 4px 6px #0000001f;
  border-radius: 6px;
  font-size: 16px;
}
#classroom-root ul.ant-menu.ant-menu-root.ant-menu-inline.ant-menu-light.left-nav li.ant-menu-item-selected span.anticon {
  background: #1890ff;
  color: #fff;
}
#classroom-root .divder {
  display: table;
  width: 100%;
  padding: 10px 15px;
  color: #8c8c8c;
  font-weight: 600;
  margin: 15px 0px;
}
#classroom-root .ant-card.ant-card-bordered.documentation-card {
  border: 0px;
  box-shadow: 0px 20px 27px #0000000d;
  border-radius: 8px;
  background: #fff;
}
#classroom-root .p-15 {
  padding: 15px;
}
#classroom-root .card-document {
  width: 32px;
  height: 32px;
  text-align: center;
  box-shadow: 0px 4px 6px #0000001f;
  border-radius: 6px;
  font-size: 19px;
  background: #1890ff;
  color: #fff;
  direction: ltr;
  margin-bottom: 15px;
}
#classroom-root .documentation-card h4 {
  margin: 0px;
}
#classroom-root .left-nav {
  /* height: 435px;
    overflow: hidden;
    overflow-y: auto;
    padding-right: 15px; */
  margin-top: 15px;
}
#classroom-root .ant-layout {
  background: #fafafa;
}
#classroom-root .pageheader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
#classroom-root .rightside-top {
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: normal;
}
#classroom-root .signin {
  white-space: nowrap;
  margin: 0px 20px;
}
#classroom-root .signin span.ant-avatar {
  margin-right: 9px;
}
#classroom-root .shadow-lg {
  box-shadow: 0 8px 26px -4px hsla(0, 0%, 8%, 0.15), 0 8px 9px -5px hsla(0, 0%, 8%, 0.06) !important;
}
#classroom-root .ant-menu-inline,
#classroom-root .ant-menu-vertical,
#classroom-root .ant-menu-vertical-left {
  border: 0px;
}
#classroom-root .header-top h4 {
  margin: 0px;
  font-size: 20px;
}
#classroom-root .header-top .subtitle {
  display: block;
  font-weight: normal;
  color: #8c8c8c;
  font-size: 14px;
}
#classroom-root .header-top {
  margin-top: -10px;
}
#classroom-root .ant-drawer.ant-drawer-right.ant-drawer-open.colorside button.ant-drawer-close {
  color: #000;
}
#classroom-root .header-top {
  border-bottom: 1px solid rgba(140, 140, 140, 0.07);
  padding-bottom: 15px;
}
#classroom-root .rightside-top span.ant-input-affix-wrapper {
  border-radius: 4px;
}
#classroom-root .rightside-top span.ant-input-affix-wrapper span.ant-input-prefix {
  color: #c0c0c0;
}
#classroom-root .setting button.ant-btn {
  padding: 0px;
  line-height: normal;
  background: transparent;
  border: 0px;
  box-shadow: none;
}
#classroom-root button.ant-btn.bell {
  padding: 0px;
  border: 0px;
  background: transparent;
  box-shadow: none;
  position: relative;
}
#classroom-root button.ant-btn.bell strong {
  position: absolute;
  top: -1px;
  right: -5px;
  display: inline-block;
  width: 20px;
  height: 20px;
  background: #f5222d;
  border-radius: 100%;
  text-align: center;
  line-height: normal;
  font-weight: normal;
  color: #fff;
}
#classroom-root .ant-dropdown-menu.ant-dropdown-menu-split.list-notifaction {
  width: 336px;
}
#classroom-root .list-notifaction ul {
  padding: 5px 10px;
  margin: 0px;
}
#classroom-root .list-notifaction ul li {
  display: block;
  width: 100%;
  clear: both;
  font-weight: 400;
  color: #67748e;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
#classroom-root .list-notifaction ul li a {
  display: block;
  padding: 10px;
  border-radius: 8px;
}
#classroom-root .list-notifaction ul li a:hover {
  color: #252f40;
  background-color: #e9ecef;
}
#classroom-root .list-notifaction ul span.ant-avatar.ant-avatar-image {
  width: 48px;
  height: 48px;
  border-radius: 0.75rem;
}
#classroom-root .list-notifaction ul li {
  padding: 0px;
}
#classroom-root .ant-dropdown-menu.ant-dropdown-menu-split.list-notifaction {
  border-radius: 0.75rem;
}
#classroom-root .list-notifaction .ant-list-item-meta-title {
  font-weight: bold;
  color: #141414;
}
#classroom-root .list-notifaction .ant-list-item-meta-title {
  font-weight: bold;
  color: #141414;
}
#classroom-root .list-notifaction .ant-avatar.ant-avatar-circle {
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
}
#classroom-root .list-notifaction ul li a span.ant-avatar-string {
  line-height: normal;
}
#classroom-root .bnb2 {
  color: #52c41a;
  font-weight: 700;
}
#classroom-root .ant-card.criclebox {
  box-shadow: 0px 20px 27px #0000000d;
  border-radius: 12px;
}
#classroom-root .number span {
  font-weight: 600;
  color: #8c8c8c;
  font-size: 14px;
}
#classroom-root .number h3 {
  font-weight: 700;
  margin-bottom: 0px;
  font-size: 30px;
}
#classroom-root .number h3 small {
  font-weight: 600;
  font-size: 14px;
}
#classroom-root .icon-box {
  width: 48px;
  height: 48px;
  text-align: center;
  background: #1890ff;
  color: #fff;
  border-radius: 0.5rem;
  margin-left: auto;
  line-height: 55px;
}
#classroom-root .icon-box span {
  color: #fff;
  font-size: 24px;
}
#classroom-root .listpage {
  padding-top: 15px;
}
#classroom-root .bar-chart {
  background: transparent linear-gradient(62deg, #00369e 0%, #005cfd 53%, #a18dff 100%) 0% 0% no-repeat padding-box;
  box-shadow: 0px 4px 6px #0000001f;
  border-radius: 8px;
}
#classroom-root .chart-vistior {
  margin-top: 30px;
}
#classroom-root .chart-vistior h5 {
  margin: 0px;
  font-weight: 700;
}
#classroom-root .ant-typography.lastweek {
  color: #8c8c8c;
  font-weight: 600;
}
#classroom-root .chart-visitor-count h4 {
  margin: 0px;
  font-weight: 700;
}
#classroom-root .chart-visitor-count span {
  color: rgba(0, 0, 0, 0.65);
  font-weight: 600;
}
#classroom-root .linechart h5 {
  font-weight: 700;
  margin: 0px;
}
#classroom-root .linechart {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
#classroom-root .sales ul {
  margin: 0px;
  padding: 0px;
  list-style: none;
}
#classroom-root .sales ul li:first-child span.anticon {
  color: #b37feb;
}
#classroom-root .sales ul li:last-child span.anticon {
  color: #1890ff;
}
#classroom-root .sales ul li {
  color: #8c8c8c;
  margin-bottom: 3px;
  cursor: pointer;
  font-weight: 600;
  font-size: 14px;
}
#classroom-root .project-ant h4 {
  margin: 0px;
  font-size: 16px;
  font-weight: bold;
  color: #141414;
}
#classroom-root span.blue {
  margin-left: 5px;
  color: #1890ff;
  font-weight: 600;
}
#classroom-root .project-ant h5 {
  margin: 0px;
  color: #141414;
  font-weight: 700;
}
#classroom-root span.blue {
  margin-left: 5px;
  color: #1890ff;
  font-weight: 600;
}
#classroom-root .project-ant {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
#classroom-root .antd-pro-pages-dashboard-analysis-style-salesExtra a {
  display: inline-block;
  padding: 7px 10px;
  color: #595959;
  border: 1px solid #d9d9d9;
  text-align: center;
  border-right: 1px solid transparent;
}
#classroom-root .antd-pro-pages-dashboard-analysis-style-salesExtra a:last-child {
  border-right: 1px solid #d9d9d9;
  border-radius: 0px 5px 5px 0px;
}
#classroom-root .antd-pro-pages-dashboard-analysis-style-salesExtra a:first-child {
  border-radius: 5px 0px 0px 5px;
}
#classroom-root .antd-pro-pages-dashboard-analysis-style-salesExtra a:hover {
  color: #1890ff;
  border: 1px solid #1890ff;
}
#classroom-root .antd-pro-pages-dashboard-analysis-style-salesExtra a.active {
  color: #1890ff;
  border: 1px solid #1890ff;
}
#classroom-root .ant-list-box th {
  text-align: left;
  border-bottom: 1px solid #0000000d;
  padding: 8px 0px;
  color: #8c8c8c;
  font-size: 12px;
  font-weight: 600;
}
#classroom-root .ant-list-box td {
  text-align: left;
  border-bottom: 1px solid #0000000d;
  padding: 16px 25px;
  white-space: nowrap;
  font-size: 12px;
  font-weight: 600;
}
#classroom-root .table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
#classroom-root .py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}
#classroom-root .px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}
#classroom-root .d-flex {
  display: flex !important;
}
#classroom-root .justify-content-center {
  justify-content: center !important;
}
#classroom-root .flex-column {
  flex-direction: column !important;
}
#classroom-root .me-3 {
  margin-right: 1rem !important;
}
#classroom-root .tootip-img {
  width: 24px;
  height: 24px;
  border-radius: 100%;
  border: 1px solid #fff;
  position: relative;
  z-index: 2;
  cursor: pointer;
}
#classroom-root .avatar-group .tootip-img + .tootip-img {
  margin-left: -10px;
}
#classroom-root .tootip-img:hover {
  z-index: 3;
  cursor: pointer;
}
#classroom-root .title-box h5 {
  margin: 0px;
}
#classroom-root .text-xs {
  color: #8c8c8c;
}
#classroom-root .percent-progress span.ant-progress-text {
  display: flex;
  margin-top: -27px;
  color: #8c8c8c;
  position: relative;
  margin-left: 0px;
}
#classroom-root .percent-progress .ant-progress-status-exception .ant-progress-text {
  color: #ff4d4f;
}
#classroom-root .ant-full-box {
  width: 100%;
  border: 1px dashed #d9d9d9;
  padding: 10px;
  height: auto;
  color: #141414;
  border-radius: 10px;
}
#classroom-root .uploadfile {
  margin-top: 15px;
}
#classroom-root .uploadfile .ant-upload.ant-upload-select {
  display: block;
}
#classroom-root .ant-timeline-item-tail {
  left: 7px;
}
#classroom-root .ant-list-box h5 {
  font-size: 14px;
}
#classroom-root .timeline-box h5 {
  margin-bottom: 0px;
  font-weight: 700;
}
#classroom-root .timelinelist h5 {
  font-size: 14px;
  font-weight: 600;
}
#classroom-root .timelinelist span {
  color: #8c8c8c;
  font-size: 12px;
}
#classroom-root .ant-muse span {
  color: #8c8c8c;
  font-weight: 600;
}
#classroom-root .ant-muse h5 {
  margin: 0px 0px 15px !important;
  font-size: 20px;
}
#classroom-root .bg-gradient-primary {
  background-image: linear-gradient(310deg, #2152ff, #21d4fd);
}
#classroom-root img {
  max-width: 100%;
}
#classroom-root .border10 {
  border-radius: 10px;
}
#classroom-root .py-4 {
  padding: 20px;
}
#classroom-root .mb-5 {
  margin-bottom: 3rem !important;
}
#classroom-root .icon-move-right .anticon-right {
  color: #40a9ff;
  margin-left: 2px;
}
#classroom-root .icon-move-right {
  color: #1890ff;
  display: block;
}
#classroom-root .mb-4 {
  margin-bottom: 1.5rem !important;
}
#classroom-root .gradent {
  background: url("./Assets/info-card-2.jpg") no-repeat center top;
  background-size: cover;
  padding: 15px;
  border-radius: 15px;
  color: #fff;
}
#classroom-root .gradent h5 {
  color: #fff;
}
#classroom-root .gradent .ant-typography {
  color: #fff;
  font-size: 20px;
}
#classroom-root .gradent .icon-move-right {
  color: #fff;
}
#classroom-root .gradent .icon-move-right .anticon-right {
  color: #fff;
}
#classroom-root .copyright {
  color: #8c8c8c;
  margin-top: 7px;
}
#classroom-root .copyright .anticon-heart {
  color: #f5222d;
  margin: 0px 5px;
}
#classroom-root .copyright a {
  margin: 0px 5px;
  color: #141414;
  font-weight: 700;
}
#classroom-root .footer-menu ul {
  margin: 0px;
  padding: 0px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
#classroom-root .footer-menu ul li {
  list-style: none;
}
#classroom-root .footer-menu ul li a {
  padding: 0.5rem 1rem;
  display: block;
  color: #8c8c8c;
}
#classroom-root span.ismobile {
  display: none;
  margin-right: 15px;
  cursor: pointer;
}
@media (max-width: 992px) {
  #classroom-root .footer-menu ul {
    justify-content: center;
  }
  #classroom-root footer.ant-layout-footer {
    padding: 0px 15px;
    text-align: center;
  }
  #classroom-root .project-ant {
    display: block;
  }
  #classroom-root .ant-filtertabs {
    text-align: right;
  }
  #classroom-root .rightside-top span.ant-input-affix-wrapper {
    width: 200px;
  }
  #classroom-root .rightside-top {
    justify-content: flex-end;
  }
  #classroom-root header.ant-layout-header.subheader {
    padding: 0px 24px 0px 0px;
  }
  #classroom-root main.ant-layout-content.content-ant {
    padding-left: 0px !important;
  }
  #classroom-root .ant-row {
    margin-left: -12px;
    margin-right: -12px;
  }
  #classroom-root span.ismobile {
    display: inline-block;
  }
  #classroom-root .bar-chart {
    width: 100% !important;
    max-width: 100%;
  }
  #classroom-root .full-width {
    max-width: 100% !important;
  }
  #classroom-root .bar-chart {
    width: 100% !important;
  }
  #classroom-root .icon-move-right {
    margin-bottom: 15px;
  }
  #classroom-root .pageheader {
    display: block;
  }
  #classroom-root .ant-cret img {
    width: 100%;
    height: 300px;
    object-fit: cover;
  }
}
#classroom-root .sidebar-color {
  padding: 1.5rem 0px;
}
#classroom-root .theme-color button {
  margin-right: 8px;
  border: 0px;
  width: 20px !important;
  height: 20px !important;
  padding: 0px;
  box-shadow: none;
  visibility: visible !important;
  line-height: 20px !important;
}
#classroom-root .theme-color button:focus {
  box-shadow: none;
  border-color: transparent;
}
#classroom-root .theme-color button span {
  opacity: 0;
}
#classroom-root .mb-2 {
  margin-bottom: 25px;
}
#classroom-root .sidebarnav-color button {
  width: 100%;
  margin-right: 10px;
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.09), 0 2px 3px -1px rgba(0, 0, 0, 0.07);
  height: 40px;
}
#classroom-root .sidebarnav-color h5 {
  margin: 0px;
}
#classroom-root .sidebarnav-color span.ant-typography {
  color: #8c8c8c;
}
#classroom-root .sidebarnav-color .trans {
  margin-top: 1rem;
  display: flex;
}
#classroom-root .sidebarnav-color button:last-child {
  margin-right: 0px;
}
#classroom-root .ant-docment button {
  width: 100%;
  margin-bottom: 10px;
  border-radius: 5px;
}
#classroom-root .viewstar {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
}
#classroom-root .viewstar a {
  display: inline-block;
  padding: 5px 10px;
  font-size: 12px;
  line-height: 16px;
  border-radius: 0em 0.25em 0.25em 0em;
  border: 1px solid #ddddde;
  border-left: 0px;
  color: #141414;
}
#classroom-root .viewstar a:first-child {
  color: #24292e;
  background-color: #eff3f6;
  border-color: #cfd3d6;
  background-image: linear-gradient(180deg, #fafbfc, #eff3f6 90%);
  border-radius: 0.25em 0 0 0.25em;
  border-left: 1px solid #ddddde;
}
#classroom-root .social {
  text-align: center;
  margin-top: 25px;
}
#classroom-root .social button {
  margin: 0px 5px;
  display: inline-flex;
  align-items: center;
}
#classroom-root .ant-thank {
  text-align: center;
  margin-top: 25px;
}
#classroom-root .setting-drwer {
  background: #fff;
  border-radius: 50%;
  bottom: 42px;
  right: 30px;
  font-size: 1.25rem;
  z-index: 990;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.16);
  width: 52px;
  height: 52px;
  text-align: center;
  line-height: 49px;
  position: fixed;
  cursor: pointer;
}
#classroom-root ul.ant-menu.ant-menu-root.ant-menu-inline.ant-menu-light.left-nav li:active {
  background: transparent;
}
#classroom-root .rightside-top .ant-input {
  height: 30px;
}
#classroom-root .aside-footer {
  padding-top: 100px;
}
#classroom-root .ant-card-body {
  padding: 16px;
}
#classroom-root ul.ant-menu.ant-menu-root.ant-menu-inline.ant-menu-light.left-nav li svg path {
  color: #bfbfbf;
}
#classroom-root .cardbody .ant-card-body {
  padding-left: 0px;
  padding-right: 0px;
}
#classroom-root .ant-card.criclebox .project-ant {
  padding-left: 24px;
  padding-right: 24px;
}
#classroom-root .ant-card.criclebox table th {
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 14px;
  padding-bottom: 14px;
}
#classroom-root .percent-progress .ant-progress-bg {
  height: 3px !important;
}
#classroom-root .uploadfile {
  padding-left: 24px;
  padding-right: 24px;
}
#classroom-root .ant-card.tablespace .ant-card-body {
  padding: 0px;
}
#classroom-root .tablespace .ant-card-head-title {
  font-size: 20px;
  font-weight: 600;
}
#classroom-root .ant-radio-button-wrapper:first-child {
  border-radius: 4px 0 0 4px;
}
#classroom-root .ant-radio-button-wrapper:last-child {
  border-radius: 0 4px 4px 0;
}
#classroom-root ul.ant-menu.ant-menu-root.ant-menu-inline.ant-menu-light.left-nav li.ant-menu-item-selected path {
  color: #fff;
}
#classroom-root .ant-avatar-group .ant-avatar-square {
  border-radius: 4px;
}
#classroom-root .shape-avatar {
  margin-right: 20px;
}
#classroom-root .shape-avatar img {
  border-radius: 4px;
}
#classroom-root .avatar-info h5 {
  font-weight: bold;
  font-size: 14px;
  line-height: 14px;
  margin-bottom: 3px;
}
#classroom-root .avatar-info p {
  font-size: 14px;
  font-weight: 400;
  color: #8c8c8c;
  margin: 0px;
}
#classroom-root .ant-table-tbody > tr > td h5 {
  font-size: 14px;
  font-weight: 600;
  color: #141414;
  margin-bottom: 0px;
}
#classroom-root .ant-table-tbody > tr > td p {
  font-size: 14px;
  color: #8c8c8c;
  margin: 0px;
}
#classroom-root .tag-primary {
  border-radius: 6px;
  padding: 2px 12px;
  font-size: 14px;
  height: auto;
  line-height: 24px;
  font-weight: 600;
}
#classroom-root .ant-employed {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 500;
  color: #8c8c8c;
}
#classroom-root .ant-employed a {
  color: #8c8c8c;
  text-decoration: none;
  font-weight: 600;
}
#classroom-root .tag-badge {
  border-radius: 6px;
  padding: 2px 12px;
  font-size: 14px;
  height: auto;
  line-height: 24px;
  font-weight: 600;
  background: #8c8c8c;
  color: #fff;
  outline: none;
}
#classroom-root .tag-badge:hover {
  background: #8c8c8c;
  color: #fff;
  border-color: #8c8c8c;
  opacity: 0.9;
}
#classroom-root .ant-avatar-group {
  align-items: center;
}
#classroom-root .mb-24 {
  margin-bottom: 24px;
}
#classroom-root .ant-table-thead > tr > th {
  color: #8c8c8c;
  font-weight: 700;
  background-color: transparent;
  font-size: 12px;
}
#classroom-root .ant-border-space th:before {
  display: none;
}
#classroom-root .ant-table-tbody > tr > td,
#classroom-root .ant-table-thead > tr > th {
  padding: 16px 25px;
}
#classroom-root .tablespace {
  overflow: hidden;
}
#classroom-root .ant-table.ant-table-middle .ant-table-title,
#classroom-root .ant-table.ant-table-middle .ant-table-footer,
#classroom-root .ant-table.ant-table-middle .ant-table-thead > tr > th,
#classroom-root .ant-table.ant-table-middle .ant-table-tbody > tr > td,
#classroom-root .ant-table.ant-table-middle tfoot > tr > th,
#classroom-root .ant-table.ant-table-middle tfoot > tr > td {
  padding: 16px 25px;
}
#classroom-root .avatar-sm {
  width: 25px;
}
#classroom-root .ant-progress-project {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
#classroom-root .ant-progress-project .ant-progress-outer {
  float: right;
}
#classroom-root .ant-progress-project .ant-progress-text {
  margin: 0px 0px 0px 0px;
  color: #8c8c8c;
  font-weight: 600;
}
#classroom-root .semibold {
  font-weight: 600;
  color: #8c8c8c;
}
#classroom-root .text-sm {
  font-size: 12px;
  color: #8c8c8c;
  font-weight: 600;
}
#classroom-root .ant-progress-project .ant-progress {
  margin-right: 15px;
}
#classroom-root .semibold {
  font-weight: 600;
  color: #8c8c8c;
}
#classroom-root .text-sm {
  font-size: 12px;
  color: #8c8c8c;
  font-weight: 600;
}
#classroom-root .ant-progress-project .ant-progress-bg {
  height: 3px !important;
}
#classroom-root .ant-table-tbody > tr > td,
#classroom-root .ant-table-thead > tr > th {
  white-space: nowrap;
}
#classroom-root .h-full {
  height: 100%;
}
@media (min-width: 1200px) {
  #classroom-root {
    /* .bar-chart {
      max-width: 438px;
    } */
    /* .full-width {
      max-width: 658px;
    } */
  }
  #classroom-root .ant-col.col-img img {
    height: 196px;
  }
}
@media (max-width: 1024px) {
  #classroom-root {
    /* .ant-col.mobile-24 {
      margin-bottom: 24px;
    } */
  }
  #classroom-root .bar-chart {
    max-width: 100%;
  }
  #classroom-root .full-width {
    max-width: 100%;
  }
}
#classroom-root body {
  overflow: visible !important;
  width: 100% !important;
}
#classroom-root .card-info-2 .ant-card-body {
  height: 100%;
}
#classroom-root .col-content {
  flex-grow: 1;
  padding: 24px;
  margin-right: 0;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
#classroom-root .card-content p {
  font-size: 16px;
}
#classroom-root .ant-layout-footer {
  padding: 0px 50px;
}
#classroom-root .layout-default.ant-layout.layout-sign-up header.ant-layout-header {
  background-color: transparent;
  color: #fff;
  margin: 0;
  padding: 0 20px;
  z-index: 1;
}
#classroom-root .layout-default .ant-layout-header {
  display: flex;
}
#classroom-root .header-col.header-nav {
  margin-left: auto;
  width: 100%;
  margin-right: auto;
}
#classroom-root .header-col.header-nav .ant-menu-overflow {
  justify-content: center;
}
#classroom-root .header-col.header-nav .ant-menu-horizontal {
  background: transparent;
  box-shadow: none;
  border: 0px;
}
#classroom-root .layout-default.ant-layout.layout-sign-up {
  padding: 11px;
}
#classroom-root .header-col.header-nav li:after {
  display: none;
}
#classroom-root .sign-up-header {
  height: 550px;
  margin-top: -62.8px;
  padding-top: 137.8px;
  border-radius: 12px;
  box-shadow: 0 20px 27px rgba(0, 0, 0, 0.05);
  background-size: cover;
  background-position: 50%;
  text-align: center;
}
#classroom-root .header-col.header-brand {
  white-space: nowrap;
}
#classroom-root .header-col.header-brand h5 {
  margin: 0px;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
}
#classroom-root .header-col.header-nav {
  color: #fff;
  font-weight: 600;
}
#classroom-root .header-col.header-nav ul li {
  color: #fff !important;
}
#classroom-root .header-col.header-nav ul li img {
  margin-top: -2px;
  margin-right: 5px;
}
#classroom-root .header-col.header-btn button {
  height: 34px;
  padding: 0 16px;
  border-radius: 34px;
  line-height: 33px;
}
#classroom-root .sign-up-header .content {
  padding-top: 40px;
  max-width: 480px;
  margin: auto;
}
#classroom-root .sign-up-header * {
  color: #fff;
}
#classroom-root .sign-up-header .content h1 {
  font-size: 48px;
  color: #fff;
}
#classroom-root .text-lg {
  font-size: 16px;
}
#classroom-root .card-signup .sign-up-gateways .ant-btn {
  margin-right: 10px;
  margin-left: 10px;
  height: 60px;
  width: 70px;
  box-shadow: none;
  border-radius: 6px;
}
#classroom-root .card-signup {
  width: 100%;
  max-width: 500px;
  margin: auto;
  box-shadow: 0 20px 27px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
  margin-top: -190px;
  margin-bottom: 20px;
}
#classroom-root .card-signup h5 {
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  color: #141414;
  margin: 0px;
}
#classroom-root .header-solid .ant-card-head {
  border-bottom: 0;
}
#classroom-root .card-signup .sign-up-gateways .ant-btn img {
  width: 20px;
}
#classroom-root .sign-up-gateways {
  text-align: center;
}
#classroom-root .m-25,
#classroom-root .mb-25,
#classroom-root .my-25 {
  margin-bottom: 25px;
}
#classroom-root .m-25,
#classroom-root .mt-25,
#classroom-root .my-25 {
  margin-top: 25px;
}
#classroom-root .text-center {
  text-align: center;
}
#classroom-root .font-semibold {
  font-weight: 600;
}
#classroom-root .text-muted {
  color: #8c8c8c;
}
#classroom-root .ant-input {
  border-radius: 6px;
}
#classroom-root .ant-input {
  font-weight: 600;
  color: #8c8c8c;
}
#classroom-root .ant-input {
  height: 40px;
}
#classroom-root .ant-switch-checked {
  background-color: #f8b04a;
}
#classroom-root .font-bold {
  font-weight: 700;
}
#classroom-root .text-dark {
  color: #141414;
}
#classroom-root .ant-btn,
#classroom-root .ant-radio-group .ant-radio-button-wrapper {
  font-weight: 600;
  font-size: 12px;
  height: 40px;
  padding: 0 15px;
  line-height: 40px;
}
#classroom-root .ant-btn {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.07);
  border-radius: 6px;
}
#classroom-root .layout-sign-up .ant-layout-footer {
  padding: 24px 50px;
}
#classroom-root .layout-default .ant-layout-footer {
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  background: transparent;
  text-align: center;
}
#classroom-root .layout-default .ant-layout-footer .ant-menu-horizontal {
  border: none;
  line-height: 1.5;
  margin: 0 0 30px;
  background-color: transparent;
  font-size: 16px;
}
#classroom-root .layout-default .ant-layout-footer .ant-menu-horizontal {
  text-align: center;
  display: block;
}
#classroom-root .layout-default .ant-layout-footer .ant-menu-horizontal > .ant-menu-item,
#classroom-root .layout-default .ant-layout-footer .ant-menu-horizontal > .ant-menu-item-active,
#classroom-root .layout-default .ant-layout-footer .ant-menu-horizontal > .ant-menu-item-selected,
#classroom-root .layout-default .ant-layout-footer .ant-menu-horizontal > .ant-menu-item:hover,
#classroom-root .layout-default .ant-layout-footer .ant-menu-horizontal > .ant-menu-submenu,
#classroom-root .layout-default .ant-layout-footer .ant-menu-horizontal > .ant-menu-submenu-active,
#classroom-root .layout-default .ant-layout-footer .ant-menu-horizontal > .ant-menu-submenu-selected,
#classroom-root .layout-default .ant-layout-footer .ant-menu-horizontal > .ant-menu-submenu:hover {
  color: #8c8c8c;
  border: none;
}
#classroom-root .layout-default .ant-layout-footer .ant-menu-horizontal > .ant-menu-item-active,
#classroom-root .layout-default .ant-layout-footer .ant-menu-horizontal > .ant-menu-item-selected,
#classroom-root .layout-default .ant-layout-footer .ant-menu-horizontal > .ant-menu-item:hover,
#classroom-root .layout-default .ant-layout-footer .ant-menu-horizontal > .ant-menu-submenu-active,
#classroom-root .layout-default .ant-layout-footer .ant-menu-horizontal > .ant-menu-submenu-selected,
#classroom-root .layout-default .ant-layout-footer .ant-menu-horizontal > .ant-menu-submenu:hover {
  color: #262626;
}
#classroom-root .layout-default.ant-layout.layout-sign-up .ant-layout-footer ul li:after {
  display: none;
}
#classroom-root .layout-default.ant-layout.layout-sign-up footer.ant-layout-footer ul li a svg {
  width: 18px;
  height: 18px;
}
#classroom-root .layout-default .ant-layout-footer .menu-nav-social a svg {
  fill: #8c8c8c;
  vertical-align: middle;
}
#classroom-root .layout-default .ant-layout-footer .menu-nav-social a:hover svg {
  fill: #1890ff;
}
#classroom-root .layout-default .ant-layout-footer .copyright a {
  color: inherit;
  font-weight: normal;
}
#classroom-root .layout-default.layout-signin header.ant-layout-header {
  display: flex;
  position: relative;
  background-color: #fff;
  color: #141414;
  box-shadow: 0 20px 27px rgba(0, 0, 0, 0.05);
  flex-direction: row;
  padding: 0 50px;
  height: 64px;
  line-height: 64px;
}
#classroom-root .fill-muted {
  fill: #8c8c8c;
}
#classroom-root .layout-default .ant-layout-header .header-brand h5 {
  white-space: nowrap;
  color: #141414;
  margin: 0;
}
#classroom-root .layout-default .ant-layout-header .header-nav a svg {
  margin-right: 5px;
}
#classroom-root .layout-default .ant-layout-header .header-nav a span,
#classroom-root .layout-default .ant-layout-header .header-nav a svg {
  vertical-align: middle;
}
#classroom-root .layout-default .ant-layout-header .header-nav a {
  color: #141414;
  padding: 6px 12px;
  font-weight: 400;
}
#classroom-root .layout-default .ant-layout-header .header-nav .ant-menu-horizontal > .ant-menu-item,
#classroom-root .layout-default .ant-layout-header .header-nav .ant-menu-horizontal > .ant-menu-item-active,
#classroom-root .layout-default .ant-layout-header .header-nav .ant-menu-horizontal > .ant-menu-item-selected,
#classroom-root .layout-default .ant-layout-header .header-nav .ant-menu-horizontal > .ant-menu-item:hover,
#classroom-root .layout-default .ant-layout-header .header-nav .ant-menu-horizontal > .ant-menu-submenu,
#classroom-root .layout-default .ant-layout-header .header-nav .ant-menu-horizontal > .ant-menu-submenu-active,
#classroom-root .layout-default .ant-layout-header .header-nav .ant-menu-horizontal > .ant-menu-submenu-selected,
#classroom-root .layout-default .ant-layout-header .header-nav .ant-menu-horizontal > .ant-menu-submenu:hover {
  color: #8c8c8c;
  font-weight: 600;
  border: none;
}
#classroom-root .layout-default .ant-layout-header .header-nav .ant-menu-horizontal > .ant-menu-item {
  padding: 0;
}
#classroom-root .sign-in {
  padding: 0 20px;
}
#classroom-root .layout-default .ant-layout-content {
  padding-top: 40px;
}
#classroom-root .font-regular {
  font-weight: 400;
}
#classroom-root .signin h1 {
  font-size: 48px;
  font-weight: 700;
  color: #141414;
}
#classroom-root .signin h5 {
  font-weight: 400;
  color: #8c8c8c;
  font-size: 20px;
  white-space: normal;
}
#classroom-root .ant-row.ant-form-item.username {
  display: block;
}
#classroom-root .ant-row.ant-form-item.username .ant-col.ant-form-item-label .ant-form-item-required:before {
  display: none;
}
#classroom-root .ant-row.ant-form-item.username .ant-col.ant-form-item-label {
  font-size: 13px;
  font-weight: 600;
  color: #141414;
}
#classroom-root .signin .ant-form-item-control-input button.ant-switch {
  margin-right: 5px;
}
#classroom-root .text-dark {
  color: #141414;
}
#classroom-root .sign-img img {
  width: 100%;
  max-width: 500px;
  margin: auto;
  display: block;
}
#classroom-root .layout-signin {
  background: #fff;
}
#classroom-root .layout-signin footer.ant-layout-footer svg {
  width: 18px;
  height: 18px;
}
#classroom-root .layout-signin footer.ant-layout-footer ul li:after {
  display: none;
}
#classroom-root .layout-default .ant-layout-footer .copyright {
  font-size: 16px;
  color: #8c8c8c;
}
#classroom-root .row-col .ant-row {
  margin-left: 0px;
  margin-right: 0px;
}
#classroom-root .layout-default.ant-layout.layout-sign-up .header-col.header-brand h5 {
  color: #fff;
}
#classroom-root .layout-default.ant-layout.layout-sign-up .header-col.header-nav a {
  color: #fff;
}
#classroom-root .layout-default.ant-layout.layout-sign-up .header-col.header-nav svg path.fill-muted {
  fill: #fff;
}
@media (min-width: 768px) {
  #classroom-root .sign-img img {
    margin: 0;
  }
  #classroom-root .card-signup {
    margin-bottom: 120px;
  }
  #classroom-root .card-signup .sign-up-gateways .ant-btn {
    width: 100px;
  }
}
#classroom-root .rightside-top .signin {
  display: inline-flex;
}
#classroom-root .rightside-top .signin svg {
  margin-right: 5px;
}
#classroom-root button.ant-btn.bell strong {
  position: absolute;
  top: -1px;
  right: -9px;
  display: inline-block;
  width: 18px;
  height: 18px;
  background: #f5222d;
  border-radius: 100%;
  text-align: center;
  line-height: normal;
  font-weight: normal;
  color: #fff;
}
#classroom-root .layout-dashboard .ant-layout-footer {
  background: transparent;
  margin: 0 20px 20px 20px;
  padding: 0;
}
#classroom-root .layout-dashboard .ant-layout-content {
  padding: 0;
  margin: 0 20px 0;
}
#classroom-root .m-10,
#classroom-root .mr-10,
#classroom-root .mx-10 {
  margin-right: 10px !important;
}
#classroom-root .ant-table-tbody > tr > td h6 {
  font-size: 14px;
  font-weight: 600;
  color: #141414;
}
#classroom-root .layout-dashboard .header-control .header-search input.ant-input {
  height: auto;
}
#classroom-root small.redtext {
  color: red;
}
#classroom-root .full-width path {
  fill: transparent;
}
#classroom-root .bar-chart line.apexcharts-xaxis-tick {
  stroke: transparent;
}
#classroom-root section.ant-layout.layout-content .ant-layout-content.content-ant {
  padding: 0px !important;
}
#classroom-root .layout-dashboard .ant-card {
  border-radius: 12px;
  box-shadow: 0 20px 27px rgba(0, 0, 0, 0.05);
}
#classroom-root .card-credit {
  background-image: url("./Assets/info-card-3.jpg");
  background-position: 50%;
  background-size: cover;
  border: none;
}
#classroom-root .card-credit * {
  color: #fff;
}
#classroom-root .card-credit .card-number {
  word-spacing: 10px;
  font-weight: 600;
  margin-bottom: 45px;
  font-size: 20px;
}
#classroom-root .ant-card-head-wrapper {
  min-height: 72px;
}
#classroom-root .card-credit .ant-card-body {
  padding-top: 15px;
}
#classroom-root .card-credit .card-footer {
  display: flex;
  align-items: flex-end;
}
#classroom-root .m-auto,
#classroom-root .ml-auto {
  margin-left: auto !important;
}
#classroom-root .m-30,
#classroom-root .mr-30,
#classroom-root .mx-30 {
  margin-right: 30px !important;
}
#classroom-root .card-credit .card-footer h6,
#classroom-root .card-credit .card-footer p {
  margin-bottom: 0;
}
#classroom-root .card-credit .col-logo img {
  max-width: 50px;
}
#classroom-root .card-credit .card-footer h6 {
  font-weight: 700;
}
#classroom-root .width-100 {
  width: 100%;
}
#classroom-root .p-20 {
  padding: "0px 20px 0px 0px";
}
#classroom-root .layout-default .ant-layout-content.p-0 {
  padding: 0px;
}
#classroom-root .pt {
  padding-top: 0px;
}
#classroom-root .widget-2 .icon {
  width: 64px;
  height: 64px;
  margin: auto;
  background-color: #1890ff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.12);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;
}
#classroom-root .widget-2 .ant-statistic-title h6 {
  font-weight: 600;
}
#classroom-root .widget-2 .ant-statistic-title p {
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 0;
}
#classroom-root .widget-2 .ant-statistic-content {
  font-family: open sans, Helvetica, Arial, sans-serif;
  font-weight: 600;
  font-size: 20px;
  padding: 12px 16px;
  border-top: 1px solid #f5f5f5;
  text-align: center;
}
#classroom-root .widget-2 .ant-statistic-title {
  text-align: center;
  padding: 16px;
}
#classroom-root .widget-2 .ant-card-body {
  padding: 0;
}
#classroom-root .widget-2 .icon img {
  width: 30px;
}
#classroom-root .ant-row-flex-middle {
  align-items: center;
}
#classroom-root .d-flex {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
#classroom-root .payment-method-card.ant-card {
  box-shadow: none;
  word-spacing: 10px;
}
#classroom-root .payment-method-card.ant-card img {
  width: 40px;
}
#classroom-root .payment-method-card.ant-card .ant-card-body {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
#classroom-root .payment-method-card.ant-card .ant-btn {
  margin-right: -20px;
}
#classroom-root .ant-btn.ant-btn-link.ant-edit-link {
  font-weight: 600;
  font-size: 12px;
  height: 40px;
  padding: 0 15px;
  box-shadow: none;
}
#classroom-root .ant-btn.ant-btn-link.ant-edit-link img {
  width: 20px;
}
#classroom-root .payment-method-card.ant-card .ant-btn {
  margin-right: -20px;
}
#classroom-root .fill-gray-7 {
  fill: #8c8c8c !important;
}
#classroom-root .payment-method-card.ant-card h6 {
  font-weight: 600;
  margin-bottom: 0;
  color: #141414;
}
#classroom-root .m-0 {
  margin: 0px;
}
#classroom-root .ant-card.ant-card-bordered.payment-method-card .ant-card-body {
  padding-top: 16px;
}
#classroom-root .header-solid .ant-card-head {
  border-bottom: 0;
}
#classroom-root .invoice-list .ant-list-item,
#classroom-root .transactions-list .ant-list-item {
  padding: 11px 0;
  border-bottom: 0;
}
#classroom-root .ant-invoice-card .ant-card-body {
  padding-top: 0px;
}
#classroom-root .invoice-list .ant-list-item-meta-title,
#classroom-root .transactions-list .ant-list-item-meta-title {
  font-size: 14px;
  font-weight: 600;
  color: #141414;
}
#classroom-root .invoice-list .ant-list-item-meta-description,
#classroom-root .transactions-list .ant-list-item-meta-description {
  font-size: 12px;
  font-weight: 600;
  color: #8c8c8c;
}
#classroom-root .invoice-list .ant-list-item-action .ant-btn,
#classroom-root .transactions-list .ant-list-item-action .ant-btn {
  font-weight: 600;
  color: #141414;
  box-shadow: none;
}
#classroom-root .ant-btn > svg,
#classroom-root .ant-btn > svg + span {
  vertical-align: middle;
}
#classroom-root .invoice-list .amount,
#classroom-root .transactions-list .amount {
  font-size: 14px;
  font-weight: 600;
  color: #8c8c8c;
}
#classroom-root .card-billing-info.ant-card {
  box-shadow: none;
  background-color: #fafafa;
  border: 1px solid #f5f5f5;
}
#classroom-root .ant-descriptions .ant-descriptions-title {
  font-size: 14px;
  font-weight: 600;
  color: #141414;
  margin-bottom: 14px;
}
#classroom-root .fill-danger {
  fill: #ff4d4f;
}
#classroom-root .card-billing-info.ant-card .darkbtn {
  color: #8c8c8c;
}
#classroom-root .col-action {
  display: flex;
}
#classroom-root .card-billing-info .col-action button {
  height: 34px;
  font-size: 12px;
  padding: 0 15px;
  box-shadow: none;
}
#classroom-root .ant-descriptions .ant-descriptions-row > td,
#classroom-root .ant-descriptions .ant-descriptions-row > th {
  padding-bottom: 6px;
}
#classroom-root .ant-descriptions .ant-descriptions-item-label {
  font-size: 13px;
  font-weight: 600;
  color: #8c8c8c;
}
#classroom-root .ant-descriptions .ant-descriptions-item-content {
  font-size: 13px;
  font-weight: 600;
  color: #141414;
}
#classroom-root .ant-descriptions .ant-descriptions-title {
  font-size: 14px;
  font-weight: 600;
  color: #141414;
  margin-bottom: 14px;
}
#classroom-root .card-billing-info.ant-card .ant-descriptions-header {
  margin-bottom: 0px;
}
#classroom-root .card-header-date {
  margin-bottom: 0;
  font-weight: 700;
  color: #8c8c8c;
}
#classroom-root .card-header-date > * {
  vertical-align: middle;
}
#classroom-root .card-header-date svg {
  margin-right: 5px;
}
#classroom-root .transactions-list .amount {
  font-size: 16px;
  font-weight: 700;
}
#classroom-root .text-light-danger {
  background: #fde3cf;
  color: #f56a00;
}
#classroom-root .text-fill {
  color: #52c41a;
  background: #edf9e7;
}
#classroom-root .text-danger {
  color: #f5222d;
}
#classroom-root .text-success {
  color: #52c41a;
}
#classroom-root .transactions-list .ant-list-item-meta {
  align-items: center;
}
#classroom-root .text-warning {
  background: #fffce7;
  color: #fadb14;
}
#classroom-root .text-warning-b {
  font-size: 14px;
  font-weight: 600;
  color: #fadb14;
}
#classroom-root .ant-card.ant-list-yes .ant-list-header {
  border: 0px;
}
#classroom-root .ant-card.ant-list-yes .ant-list-header h6 {
  font-size: 12px;
  font-weight: 600;
  color: #8c8c8c;
  margin: 6px 0;
}
#classroom-root .ant-list.ant-list-split.transactions-list.ant-newest .ant-list-header {
  padding-top: 0px;
}
#classroom-root .profile-nav-bg {
  height: 300px;
  margin-top: -137.8px;
  border-radius: 12px;
  box-shadow: 0 20px 27px rgba(0, 0, 0, 0.05);
  background-size: cover;
  background-position: 50%;
}
#classroom-root section.ant-layout.ant-layout-has-sider.layout-dashboard.layout-profile span.ant-page-header-heading-title {
  color: #fff;
}
#classroom-root section.ant-layout.ant-layout-has-sider.layout-dashboard.layout-profile span.ant-breadcrumb-separator {
  color: #fff;
}
#classroom-root section.ant-layout.ant-layout-has-sider.layout-dashboard.layout-profile span.ant-breadcrumb-link {
  color: #fff;
}
#classroom-root section.ant-layout.ant-layout-has-sider.layout-dashboard.layout-profile .header-control svg path {
  fill: #fff;
}
#classroom-root section.ant-layout.ant-layout-has-sider.layout-dashboard.layout-profile .btn-sign-in {
  color: #fff;
}
#classroom-root .layout-profile .ant-layout-header {
  margin: 10px 20px;
}
#classroom-root .card-profile-head .ant-card-head {
  border-bottom: 0px;
  padding: 0px 16px;
}
#classroom-root .card-profile-head {
  margin: -53px 0 24px;
}
#classroom-root .card-profile-head .ant-card-head .ant-avatar {
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  margin: 0;
}
#classroom-root .card-profile-head .ant-card-head .avatar-info {
  margin-left: 16px;
}
#classroom-root .ant-card-head-title p {
  font-weight: 600;
  color: #8c8c8c;
  margin-bottom: 0;
}
#classroom-root .card-profile-head .ant-card-head .avatar-info h4 {
  font-size: 24px;
}
#classroom-root .card-profile-head .ant-card-head .avatar-info h4 {
  font-size: 24px;
}
#classroom-root .card-profile-head .ant-radio-group .ant-radio-button-wrapper {
  height: auto;
  font-weight: 700;
}
#classroom-root .ant-radio-button-wrapper {
  color: #595959;
}
#classroom-root .layout-profile .ant-card-head {
  padding: 0 16px;
}
#classroom-root .settings-list {
  list-style: none;
  padding: 0;
}
#classroom-root .settings-list > li:first-child {
  padding-top: 0;
}
#classroom-root .settings-list > li {
  padding: 12px 0;
}
#classroom-root .settings-list > li h6 {
  font-size: 12px;
  color: #8c8c8c;
  margin: 0;
  font-weight: 700;
}
#classroom-root .settings-list > li span {
  margin-left: 16px;
  color: #141414;
}
#classroom-root .ant-btn-link {
  box-shadow: none;
}
#classroom-root .card-profile-information hr {
  opacity: 0.2;
}
#classroom-root .p-5,
#classroom-root .pr-5,
#classroom-root .px-5 {
  padding-right: 5px !important;
}
#classroom-root .p-5,
#classroom-root .pl-5,
#classroom-root .px-5 {
  padding-left: 5px !important;
}
#classroom-root .m-5,
#classroom-root .mr-5,
#classroom-root .mx-5 {
  margin-right: 5px !important;
}
#classroom-root .m-5,
#classroom-root .ml-5,
#classroom-root .mx-5 {
  margin-left: 5px !important;
}
#classroom-root .card-profile-information .ant-descriptions .ant-descriptions-row > td,
#classroom-root .card-profile-information .ant-descriptions .ant-descriptions-row > th {
  padding-bottom: 16px;
}
#classroom-root .conversations-list .ant-list-item-meta-description {
  font-size: 12px;
  font-weight: 600;
  color: #8c8c8c;
}
#classroom-root .conversations-list .ant-avatar {
  border-radius: 10.5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.12);
}
#classroom-root .conversations-list .ant-list-item {
  padding: 14px 0;
}
#classroom-root .ant-card-head-title p {
  font-weight: 600;
  color: #8c8c8c;
  margin-bottom: 0;
}
#classroom-root .ant-card-head-title h6 {
  margin-bottom: 0;
}
#classroom-root .card-project.ant-card {
  box-shadow: none;
  border: none;
}
#classroom-root .card-project.ant-card .ant-card-cover img {
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.12);
}
#classroom-root .card-project.ant-card .card-tag,
#classroom-root .card-project.ant-card p {
  font-weight: 600;
  color: #8c8c8c;
  margin-bottom: 5px;
}
#classroom-root .card-project.ant-card h5 {
  font-weight: 600;
  font-size: 20px;
}
#classroom-root .card-project.ant-card .card-tag,
#classroom-root .card-project.ant-card p {
  font-weight: 600;
  color: #8c8c8c;
  margin-bottom: 5px;
}
#classroom-root .card-project.ant-card p {
  margin-bottom: 0;
}
#classroom-root .card-project.ant-card .ant-card-body {
  padding: 16px 0 0;
}
#classroom-root .card-project.ant-card .card-footer {
  margin-top: 22px;
}
#classroom-root .avatar-chips .ant-avatar-image {
  border: 1px solid #fff;
}
#classroom-root .avatar-chips .ant-avatar-image:hover,
#classroom-root .avatar-chips .ant-space-item:hover {
  z-index: 1;
}
#classroom-root .projects-uploader {
  height: 100%;
  border-radius: 8px;
  border-color: #d9d9d9;
}
#classroom-root .projects-uploader .ant-upload {
  width: 100%;
  height: 100%;
  margin: 0;
  border-radius: 8px;
  padding: 8px;
}
#classroom-root .projects-uploader .ant-upload-list.ant-upload-list-picture-card {
  height: 100%;
}
#classroom-root .projects-uploader .ant-upload.ant-upload-select-picture-card {
  background-color: transparent;
}
#classroom-root .projects-uploader svg {
  width: 20px;
  height: 20px;
}
#classroom-root .text-right {
  text-align: right;
}
#classroom-root .layout-dashboard-rtl {
  background: transparent;
  text-align: right;
  direction: rtl;
  position: relative;
  overflow-x: hidden;
}
#classroom-root .layout-dashboard-rtl .ant-layout-sider.sider-primary .ant-menu-item .icon,
#classroom-root .layout-dashboard-rtl .ant-layout-sider.sider-primary .ant-menu-submenu .icon {
  margin-left: 11px;
  margin-right: 0;
}
#classroom-root .layout-dashboard-rtl .ant-timeline-item-tail {
  right: 6.5px;
  left: auto;
}
#classroom-root .layout-dashboard-rtl .ant-timeline-item-content {
  margin: 0 33px 0 0;
}
#classroom-root .ant-timeline-item-head {
  width: 15px;
  height: 15px;
  border-width: 3px;
}
#classroom-root .layout-dashboard-rtl .ant-radio-button-wrapper:first-child {
  border-right-width: 1px;
  border-radius: 0 4px 4px 0;
}
#classroom-root .layout-dashboard-rtl .ant-list-box td {
  text-align: right;
}
#classroom-root .layout-dashboard-rtl .ant-space-horizontal {
  direction: ltr;
}
#classroom-root .layout-dashboard-rtl .ant-btn > .anticon + span,
#classroom-root .ant-btn > span + .anticon {
  margin-right: 8px;
  margin-left: 0px;
}
#classroom-root .layout-dashboard-rtl .setting-drwer {
  left: 30px;
  right: auto;
}
#classroom-root .layout-dashboard-rtl ul.ant-menu.ant-menu-root.ant-menu-inline.ant-menu-light {
  text-align: right;
}
#classroom-root .layout-dashboard-rtl .avatar-group.mt-2 {
  direction: ltr;
}
#classroom-root .ant-list-box table tr:hover td {
  background: #fafafa;
}
#classroom-root .layout-dashboard-rtl .icon-box {
  margin-left: 0px;
  margin-right: auto;
}
#classroom-root .layout-dashboard-rtl .m-10,
#classroom-root .layout-dashboard-rtl .mr-10,
#classroom-root .layout-dashboard-rtl .mx-10 {
  margin-left: 10px !important;
}
#classroom-root .layout-dashboard-rtl .ant-progress-show-info .ant-progress-outer {
  margin-right: 0px;
  padding-right: 0px;
}
#classroom-root .layout-dashboard-rtl .ant-badge-count,
#classroom-root .layout-dashboard-rtl .ant-badge-dot,
#classroom-root .layout-dashboard-rtl .ant-badge .ant-scroll-number-custom-component {
  left: 0;
  right: auto;
  transform: translate(-50%, -50%);
}
#classroom-root .layout-dashboard-rtl .header-control .btn-sign-in svg {
  margin-left: 4px;
  margin-right: 0px;
}
#classroom-root .ant-drawer.drawer-sidebar.drawer-sidebar-rtl aside.ant-layout-sider.ant-layout-sider-light.sider-primary.ant-layout-sider-primary {
  left: auto;
  right: 0px;
  direction: rtl;
}
#classroom-root .layout-dashboard.layout-dashboard-rtl .header-control span.ant-badge {
  margin: 0px 7px;
}
#classroom-root .layout-dashboard.layout-dashboard-rtl span.ant-input-prefix {
  margin-right: 0px;
  margin-left: 4px;
}
#classroom-root .layout-dashboard-rtl .ant-drawer-left .ant-drawer-content-wrapper {
  left: 0;
  direction: rtl;
}
#classroom-root .settings-drawer.settings-drawer-rtl {
  left: 0;
  direction: rtl;
}
#classroom-root .settings-drawer.settings-drawer-rtl button.ant-drawer-close {
  left: 0px;
  right: auto;
  margin-right: 0px;
  margin-left: var(--scroll-bar);
  padding-left: calc(20px - var(--scroll-bar));
}
#classroom-root .layout-dashboard-rtl .ant-drawer-left .ant-drawer-content-wrapper {
  left: 0;
  direction: rtl;
}
#classroom-root .settings-drawer.settings-drawer-rtl {
  left: 0;
  direction: rtl;
}
#classroom-root .settings-drawer.settings-drawer-rtl button.ant-drawer-close {
  left: 0px;
  right: auto;
  margin-right: 0px;
  margin-left: var(--scroll-bar);
  padding-left: calc(20px - var(--scroll-bar));
}
#classroom-root .settings-drawer.settings-drawer-rtl .sidebarnav-color button {
  margin-right: 0px;
  margin-left: 10px;
}
#classroom-root .settings-drawer.settings-drawer-rtl .viewstar a:first-child {
  border-radius: 0em 0.25em 0.25em 0;
  border-right: 1px solid #ddddde;
}
#classroom-root .settings-drawer.settings-drawer-rtl .viewstar a {
  border-radius: 0.25em 0 0 0.25em;
  border: 1px solid #ddddde;
  border-right: 0px;
}
#classroom-root .social svg path {
  fill: #fff;
}
#classroom-root .settings-drawer.settings-drawer-rtl .social svg {
  margin-right: 0px;
  margin-left: 5px;
}
#classroom-root .social svg {
  width: 15px;
  height: 15px;
}
#classroom-root .layout-dashboard .ant-affix .ant-layout-header {
  background: #fff;
  box-shadow: 0 20px 27px rgba(0, 0, 0, 0.05);
  margin: 10px 20px;
  z-index: 10;
}
#classroom-root aside.ant-layout-sider.ant-layout-sider-light.sider-primary.ant-layout-sider-primary.active-route a.active {
  background: transparent;
  box-shadow: none;
}
#classroom-root .uploadfile.pb-15 {
  padding-bottom: 16px;
}
#classroom-root .ant-row.ant-form-item.aligin-center .ant-form-item-control-input-content {
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.65);
}
#classroom-root .uploadfile.shadow-none .ant-upload.ant-upload-select.ant-upload-select-text .ant-btn.ant-full-box {
  box-shadow: none;
}
#classroom-root .ant-btn-dashed {
  color: rgba(0, 0, 0, 0.65);
}
#classroom-root button.ant-btn.ant-btn-dashed.ant-full-box svg {
  width: 16px;
  height: 16px;
}
#classroom-root .ant-btn-dashed.active,
#classroom-root .ant-btn-dashed:active {
  color: #096dd9;
  background-color: #fff;
  border-color: #096dd9;
}
#classroom-root section.ant-layout.ant-layout-has-sider.layout-dashboard.layout-profile .header-control span.ant-input-affix-wrapper.header-search .anticon-search svg path {
  fill: #8c8c8c;
}
@media (min-width: 992px) {
  #classroom-root .layout-dashboard .ant-layout-footer {
    margin: 0 0 20px 20px;
  }
  #classroom-root .layout-dashboard .ant-layout-sider.sider-primary {
    margin: 20px 0 0 20px;
    padding: 13px 20px;
    height: calc(100vh - 20px);
  }
  #classroom-root .layout-dashboard .ant-layout {
    width: auto;
    flex-shrink: 1;
    margin-left: 270px;
  }
  #classroom-root .layout-dashboard .header-control .sidebar-toggler {
    display: none;
  }
}
@media (min-width: 768px) {
  #classroom-root .layout-dashboard .ant-layout-header {
    margin: 10px 20px;
  }
  #classroom-root .layout-dashboard .header-control {
    margin-top: 0;
  }
  #classroom-root .layout-dashboard .header-control .header-search {
    margin: 0 7px;
  }
  #classroom-root .layout-dashboard .header-control .btn-sign-in span {
    display: inline;
  }
  #classroom-root .profile-nav-bg {
    margin-top: -87.8px;
  }
  #classroom-root .card-profile-head {
    margin: -53px 24px 24px;
  }
}
@media (min-width: 992px) {
  #classroom-root .card-billing-info.ant-card .ant-card-body {
    display: flex;
  }
  #classroom-root .layout-dashboard-rtl {
    overflow: auto;
  }
  #classroom-root .layout-dashboard-rtl .ant-layout-sider.sider-primary {
    margin: 20px 20px 0 0;
    height: calc(100vh - 20px);
  }
  #classroom-root .layout-dashboard-rtl .ant-layout-sider.sider-primary {
    right: 0;
    left: auto;
  }
  #classroom-root .layout-dashboard-rtl .ant-layout-sider.sider-primary {
    right: 0;
    left: auto;
  }
  #classroom-root .layout-dashboard-rtl .ant-layout {
    margin-right: 270px;
    margin-left: 0;
  }
  #classroom-root .layout-dashboard-rtl .ant-layout-footer {
    margin: 0 20px 20px 0;
  }
}
@media (max-width: 768px) {
  #classroom-root .layout-dashboard.layout-dashboard-rtl .header-control {
    justify-content: flex-end;
  }
}
#classroom-root #classroom-dashboard .img-section {
  position: relative;
  display: flex;
  justify-content: flex-end;
}
#classroom-root #classroom-dashboard .img-section > * {
  opacity: 0;
  will-change: transform;
}
#classroom-root #classroom-dashboard .img-section > img {
  max-width: 80%;
}
#classroom-root #classroom-dashboard .img-section .img-programmer-bg {
  transition: all 700ms ease;
}
#classroom-root #classroom-dashboard .img-section .img-programmer {
  position: absolute;
  top: 0;
  left: 0;
  transition: all 700ms ease;
}
#classroom-root #classroom-dashboard .img-section .img-data-courses {
  position: absolute;
  top: 0;
  right: 0;
  transform: translateY(400px);
  transition: all 1000ms ease;
}
#classroom-root #classroom-dashboard .img-section .img-data-tutor {
  position: absolute;
  bottom: 0;
  right: 0;
  transform: translateX(800px);
  transition: all 300ms ease;
}
#classroom-root #classroom-dashboard .img-section.move-img-programmer .img-programmer-bg {
  opacity: 1;
  animation: moveit 700ms ease forwards;
  will-change: transform;
}
#classroom-root #classroom-dashboard .img-section.move-img-programmer .img-programmer {
  opacity: 1;
  animation: moveit 700ms ease forwards;
  will-change: transform;
}
#classroom-root #classroom-dashboard .img-section.move-img-programmer .img-data-courses {
  opacity: 1;
  will-change: transform;
  transform: translateY(0);
}
#classroom-root #classroom-dashboard .img-section.move-img-programmer .img-data-tutor {
  opacity: 1;
  will-change: transform;
  transform: translateX(0);
}
#classroom-root #classroom-dashboard .img-section .waves {
  opacity: 1;
  width: 410px;
  height: 410px;
  right: 0;
  position: absolute;
  bottom: -250px;
  z-index: -1;
}
#classroom-root #classroom-dashboard .img-section .waves > div {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  border-radius: 50%;
  border: 1px solid rgba(32, 180, 134, 0.25);
  animation-name: spread;
  animation-duration: 2.1s;
  animation-delay: 0.5;
  animation-iteration-count: infinite;
  animation-timing-function: ease-out;
  animation-fill-mode: backwards;
}
#classroom-root #classroom-dashboard .img-section .waves > div:nth-child(2) {
  animation-delay: 0.7s;
  animation-timing-function: linear;
}
#classroom-root #classroom-dashboard .img-section .waves > div:nth-child(3) {
  animation-delay: 1s;
  animation-timing-function: linear;
}
#classroom-root #classroom-dashboard .img-section .waves > div:nth-child(4) {
  animation-delay: 1.5s;
  animation-timing-function: linear;
}
#classroom-root #classroom-dashboard .text-section {
  position: relative;
}
#classroom-root #classroom-dashboard .text-section h1 {
  color: #101828;
  font-size: 34px;
  font-style: normal;
  font-weight: 500;
  line-height: 82px;
  /* 128.125% */
  letter-spacing: -0.5px;
  text-transform: capitalize;
}
#classroom-root #classroom-dashboard .text-section h1 span {
  background: linear-gradient(112deg, #682696 0%, #6A2694 0.3%, #94256D 8.22%, #B7244D 16.3%, #D22333 24.46%, #E52221 32.72%, #F02217 41.15%, #F42213 50%, #F8B04A 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
#classroom-root #classroom-dashboard .text-section h1 span.span-2 {
  background: linear-gradient(174deg, #682696 0%, #6A2694 0.3%, #94256D 8.22%, #B7244D 16.3%, #D22333 24.46%, #E52221 32.72%, #F02217 41.15%, #F42213 50%, #F8B04A 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
#classroom-root #classroom-dashboard .text-section h1 span.span-3 {
  background: linear-gradient(97deg, #682696 0%, #6A2694 0.3%, #94256D 8.22%, #B7244D 16.3%, #D22333 24.46%, #E52221 32.72%, #F02217 41.15%, #F42213 50%, #F8B04A 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
#classroom-root #classroom-dashboard .text-section p {
  color: #667085;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 180%;
  margin-top: 20px;
}
#classroom-root #classroom-dashboard .text-section .search-box {
  position: relative;
  margin-top: 3rem;
}
#classroom-root #classroom-dashboard .text-section .search-box input {
  width: 100%;
  height: 72px;
  border-radius: 8px;
  background-color: #ffffff;
  border: 0;
  padding: 0 30px;
  filter: drop-shadow(-4px -4px 44px rgba(0, 0, 0, 0.08));
}
#classroom-root #classroom-dashboard .text-section .search-box input::placeholder {
  color: #667085;
  opacity: 0.5;
}
#classroom-root #classroom-dashboard .text-section .search-box button {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  border: 0;
  text-indent: -4999px;
  width: 65px;
  background: url("./Assets/search.svg") center no-repeat;
}
#classroom-root #classroom-dashboard .text-section .objectives {
  margin-top: 2rem;
}
#classroom-root #classroom-dashboard .text-section .objectives ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
#classroom-root #classroom-dashboard .text-section .objectives ul li {
  display: flex;
  position: relative;
  white-space: nowrap;
  float: left;
}
#classroom-root #classroom-dashboard .text-section .objectives ul li img {
  padding-right: 1rem;
}
#classroom-root #classroom-dashboard .text-section .waves {
  opacity: 1;
  width: 410px;
  height: 410px;
  left: -100px;
  position: absolute;
  top: 0;
  z-index: -1;
}
#classroom-root #classroom-dashboard .text-section .waves > div {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  border-radius: 50%;
  border: 1px solid rgba(32, 180, 134, 0.25);
  animation-name: spread;
  animation-duration: 2.1s;
  animation-delay: 0.5;
  animation-iteration-count: infinite;
  animation-timing-function: ease-out;
  animation-fill-mode: backwards;
}
#classroom-root #classroom-dashboard .text-section .waves > div:nth-child(2) {
  animation-delay: 0.7s;
  animation-timing-function: linear;
}
#classroom-root #classroom-dashboard .text-section .waves > div:nth-child(3) {
  animation-delay: 1s;
  animation-timing-function: linear;
}
#classroom-root #classroom-dashboard .text-section .waves > div:nth-child(4) {
  animation-delay: 1.5s;
  animation-timing-function: linear;
}
@keyframes moveit {
  0% {
    transform: scale3d(0.96, 0.96, 1);
  }
  20% {
    transform: scale3d(1.1, 1.1, 1);
  }
  40% {
    transform: scale3d(0.98, 0.98, 1);
  }
  60% {
    transform: scale3d(1.05, 1.05, 1);
  }
  80% {
    transform: scale3d(1.01, 1.01, 1);
  }
  100% {
    transform: scale3d(1, 1, 1);
  }
}
@keyframes spread {
  0% {
    transform: scale(0);
    opacity: 1;
  }
  40% {
    transform: scale(0);
    opacity: 1;
  }
  100% {
    transform: scale(1);
  }
}
#classroom-root #my-courses .top-section {
  position: relative;
}
#classroom-root #my-courses .top-section .title {
  display: flex;
  flex-direction: column;
  margin-bottom: 3rem;
}
#classroom-root #my-courses .top-section .title p.small-title {
  color: #6941C6;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
#classroom-root #my-courses .top-section .title h2 {
  font-size: 36px;
  max-width: 50rem;
  font-style: normal;
  font-weight: 600;
  line-height: 44px;
  letter-spacing: -0.72px;
}
#classroom-root #my-courses .top-section .title p.desc {
  margin-top: 0.7rem;
  color: #667085;
}
#classroom-root #my-courses .top-section .show-all-button {
  position: absolute;
  right: 0;
  top: 40px;
  background: #fff;
  background-clip: padding-box;
  border: solid 5px transparent;
  border-radius: 1em;
}
#classroom-root #my-courses .top-section .show-all-button a {
  width: 150px;
  height: 50px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: space-around;
  background: linear-gradient(112deg, #682696 0%, #6A2694 0.3%, #94256D 8.22%, #B7244D 16.3%, #D22333 24.46%, #E52221 32.72%, #F02217 41.15%, #F42213 50%, #F8B04A 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
#classroom-root #my-courses .top-section .show-all-button a:hover {
  background: linear-gradient(16deg, #682696 0%, #6A2694 0.3%, #94256D 8.22%, #B7244D 16.3%, #D22333 24.46%, #E52221 32.72%, #F02217 41.15%, #F42213 50%, #F8B04A 100%);
  border-radius: 10px;
  animation: gradient2 1s ease;
  background-clip: text;
  -webkit-background-clip: unset;
  -webkit-text-fill-color: #ffffff;
}
#classroom-root #my-courses .top-section .show-all-button:before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  margin: -2px;
  border-radius: 13px;
  background: linear-gradient(16deg, #682696 0%, #6A2694 0.3%, #94256D 8.22%, #B7244D 16.3%, #D22333 24.46%, #E52221 32.72%, #F02217 41.15%, #F42213 50%, #F8B04A 100%);
}
#classroom-root #my-courses .courses-list {
  position: relative;
}
#classroom-root #my-courses .courses-list .courses-filter-wrapper::after {
  content: "";
  display: block;
  height: 10px;
  position: absolute;
  right: 0;
  top: -10px;
  height: 70px;
  width: 200px;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #FFF 85.49%);
}
#classroom-root #my-courses .courses-list .courses-filter-wrapper .courses-filter {
  margin-bottom: 2rem;
  display: flex;
  overflow-x: scroll;
  position: relative;
  overflow-y: visible;
}
#classroom-root #my-courses .courses-list .courses-filter-wrapper .courses-filter::-webkit-scrollbar {
  padding-top: 23px;
}
#classroom-root #my-courses .courses-list .courses-filter-wrapper .courses-filter button {
  margin-right: 1.5rem;
  background: #ffffff;
  color: #101828;
  font-family: 'Maven Pro', sans-serif;
  border: 1px solid rgba(35, 15, 15, 0.2);
  border-radius: 100px;
  padding: 0.6rem 1.5rem;
  min-width: fit-content;
}
#classroom-root #my-courses .courses-list .courses-filter-wrapper .courses-filter button:hover {
  color: #ffffff;
  background: linear-gradient(16deg, #682696 0%, #6A2694 0.3%, #94256D 8.22%, #B7244D 16.3%, #D22333 24.46%, #E52221 32.72%, #F02217 41.15%, #F42213 50%, #F8B04A 100%);
  animation: gradient2 1s ease;
  border-color: #ffffff;
}
#classroom-root #my-courses .courses-list .courses .course-item .course-inner-item {
  margin: 1.5rem 1rem;
  border-radius: 8px;
  padding: 1rem;
  box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.08), 0px 12px 16px -4px rgba(16, 24, 40, 0.03);
  cursor: pointer;
  transition: all 0.1s ease-in;
}
#classroom-root #my-courses .courses-list .courses .course-item .course-inner-item:hover {
  box-shadow: 0px 53px 179px 101px rgba(209, 35, 50, 0.03), 0px 12px 25px 29px rgba(248, 176, 74, 0.08);
}
#classroom-root #my-courses .courses-list .courses .course-item .course-inner-item:hover h5:before {
  animation: gradient1 700ms ease forwards;
}
#classroom-root #my-courses .courses-list .courses .course-item .course-inner-item img {
  max-width: 100%;
  max-height: 220px;
  border-radius: 4px;
}
#classroom-root #my-courses .courses-list .courses .course-item .course-inner-item p.category {
  color: #6941C6;
  margin-top: 2rem;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}
#classroom-root #my-courses .courses-list .courses .course-item .course-inner-item h5 {
  height: 65px;
  overflow: hidden;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  padding-right: 10%;
  position: relative;
}
#classroom-root #my-courses .courses-list .courses .course-item .course-inner-item h5:before {
  content: "";
  display: inline-block;
  height: 25px;
  position: absolute;
  right: -30px;
  top: 5px;
  right: 0;
  width: 25px;
  background: url("./Assets/arrow-up-right.svg") center no-repeat;
  transition: all 700ms ease;
}
#classroom-root #my-courses .courses-list .courses .course-item .course-inner-item p.desc {
  font-family: 'Inter', sans-serif;
  color: #667085;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  min-height: 75px;
}
#classroom-root #my-courses .courses-list .learn-more {
  display: flex;
  align-items: center;
}
#classroom-root #my-courses .courses-list .learn-more .learn-more-button {
  width: 100%;
  background: #fff;
  background-clip: padding-box;
  border: solid 5px transparent;
  border-radius: 1em;
  display: flex;
}
#classroom-root #my-courses .courses-list .learn-more .learn-more-button a {
  height: 50px;
  width: 100%;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: space-around;
  background: linear-gradient(112deg, #682696 0%, #6A2694 0.3%, #94256D 8.22%, #B7244D 16.3%, #D22333 24.46%, #E52221 32.72%, #F02217 41.15%, #F42213 50%, #F8B04A 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
#classroom-root #my-courses .courses-list .learn-more .learn-more-button a:hover {
  background: linear-gradient(16deg, #682696 0%, #6A2694 0.3%, #94256D 8.22%, #B7244D 16.3%, #D22333 24.46%, #E52221 32.72%, #F02217 41.15%, #F42213 50%, #F8B04A 100%);
  border-radius: 10px;
  animation: gradient2 1s ease;
  background-clip: text;
  -webkit-background-clip: unset;
  -webkit-text-fill-color: #ffffff;
}
#classroom-root #my-courses .courses-list .learn-more .learn-more-button:before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  margin: -2px;
  border-radius: 13px;
  background: linear-gradient(16deg, #682696 0%, #6A2694 0.3%, #94256D 8.22%, #B7244D 16.3%, #D22333 24.46%, #E52221 32.72%, #F02217 41.15%, #F42213 50%, #F8B04A 100%);
}
#classroom-root #courses .top-section {
  position: relative;
}
#classroom-root #courses .top-section .title {
  display: flex;
  flex-direction: column;
  margin-bottom: 3rem;
}
#classroom-root #courses .top-section .title p.small-title {
  color: #6941C6;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
#classroom-root #courses .top-section .title h2 {
  font-size: 36px;
  max-width: 50rem;
  font-style: normal;
  font-weight: 600;
  line-height: 44px;
  letter-spacing: -0.72px;
}
#classroom-root #courses .top-section .title p.desc {
  margin-top: 0.7rem;
  color: #667085;
}
#classroom-root #courses .top-section .show-all-button {
  position: absolute;
  right: 0;
  top: 40px;
  background: #fff;
  background-clip: padding-box;
  border: solid 5px transparent;
  border-radius: 1em;
}
#classroom-root #courses .top-section .show-all-button a {
  width: 150px;
  height: 50px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: space-around;
  background: linear-gradient(112deg, #682696 0%, #6A2694 0.3%, #94256D 8.22%, #B7244D 16.3%, #D22333 24.46%, #E52221 32.72%, #F02217 41.15%, #F42213 50%, #F8B04A 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
#classroom-root #courses .top-section .show-all-button a:hover {
  background: linear-gradient(16deg, #682696 0%, #6A2694 0.3%, #94256D 8.22%, #B7244D 16.3%, #D22333 24.46%, #E52221 32.72%, #F02217 41.15%, #F42213 50%, #F8B04A 100%);
  border-radius: 10px;
  animation: gradient2 1s ease;
  background-clip: text;
  -webkit-background-clip: unset;
  -webkit-text-fill-color: #ffffff;
}
#classroom-root #courses .top-section .show-all-button:before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  margin: -2px;
  border-radius: 13px;
  background: linear-gradient(16deg, #682696 0%, #6A2694 0.3%, #94256D 8.22%, #B7244D 16.3%, #D22333 24.46%, #E52221 32.72%, #F02217 41.15%, #F42213 50%, #F8B04A 100%);
}
#classroom-root #courses .courses-list {
  position: relative;
}
#classroom-root #courses .courses-list .courses-filter-wrapper::after {
  content: "";
  display: block;
  height: 10px;
  position: absolute;
  right: 0;
  top: -10px;
  height: 70px;
  width: 200px;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #FFF 85.49%);
}
#classroom-root #courses .courses-list .courses-filter-wrapper .courses-filter {
  margin-bottom: 2rem;
  display: flex;
  overflow-x: scroll;
  position: relative;
  overflow-y: visible;
}
#classroom-root #courses .courses-list .courses-filter-wrapper .courses-filter::-webkit-scrollbar {
  padding-top: 23px;
}
#classroom-root #courses .courses-list .courses-filter-wrapper .courses-filter button {
  margin-right: 1.5rem;
  background: #ffffff;
  color: #101828;
  font-family: 'Maven Pro', sans-serif;
  border: 1px solid rgba(35, 15, 15, 0.2);
  border-radius: 100px;
  padding: 0.6rem 1.5rem;
  min-width: fit-content;
}
#classroom-root #courses .courses-list .courses-filter-wrapper .courses-filter button:hover {
  color: #ffffff;
  background: linear-gradient(16deg, #682696 0%, #6A2694 0.3%, #94256D 8.22%, #B7244D 16.3%, #D22333 24.46%, #E52221 32.72%, #F02217 41.15%, #F42213 50%, #F8B04A 100%);
  animation: gradient2 1s ease;
  border-color: #ffffff;
}
#classroom-root #courses .courses-list .courses .course-item .course-inner-item {
  margin: 1.5rem 1rem;
  border-radius: 8px;
  padding: 1rem;
  box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.08), 0px 12px 16px -4px rgba(16, 24, 40, 0.03);
  cursor: pointer;
  transition: all 0.1s ease-in;
}
#classroom-root #courses .courses-list .courses .course-item .course-inner-item:hover {
  box-shadow: 0px 53px 179px 101px rgba(209, 35, 50, 0.03), 0px 12px 25px 29px rgba(248, 176, 74, 0.08);
}
#classroom-root #courses .courses-list .courses .course-item .course-inner-item:hover h5:before {
  animation: gradient1 700ms ease forwards;
}
#classroom-root #courses .courses-list .courses .course-item .course-inner-item img {
  max-width: 100%;
  max-height: 220px;
  border-radius: 4px;
}
#classroom-root #courses .courses-list .courses .course-item .course-inner-item p.category {
  color: #6941C6;
  margin-top: 2rem;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}
#classroom-root #courses .courses-list .courses .course-item .course-inner-item h5 {
  height: 65px;
  overflow: hidden;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  padding-right: 10%;
  position: relative;
}
#classroom-root #courses .courses-list .courses .course-item .course-inner-item h5:before {
  content: "";
  display: inline-block;
  height: 25px;
  position: absolute;
  right: -30px;
  top: 5px;
  right: 0;
  width: 25px;
  background: url("./Assets/arrow-up-right.svg") center no-repeat;
  transition: all 700ms ease;
}
#classroom-root #courses .courses-list .courses .course-item .course-inner-item p.desc {
  font-family: 'Inter', sans-serif;
  color: #667085;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  min-height: 75px;
}
